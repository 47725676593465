.full-page {
  margin-top: 60px;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(to bottom, rgb(255, 255, 255), black);
}

:root {
  --swiper-pagination-color: rgba(255, 255, 255, 1);
  --swiper-pagination-bullet-inactive-color: rgba(255, 255, 255, 1);
  --swiper-navigation-color: rgb(255, 255, 255, 1);

}

.swiper {
  user-select: none;
  box-sizing: border-box;
  overflow: visible;
  width: 90%;

  height: 100%;
  padding: 1px 10px;

}

.swiper2 {
  user-select: none;
  box-sizing: border-box;
  overflow: visible;
  width: 400px;
  margin-top: 40px;
  height: auto;
  padding: 1px 10px;
}

.swiper.swiper3 {
  min-width: 600px;
  max-width: 680px;
}

.swiper4 {
  width: 350px;
}

.left-side {
  float: left;
  margin: 50px;
  margin-left: 200px;
}

.right-side {
  margin: 200px;

}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  max-height: 75vh;
  min-height: 440px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

}

.swiper-slide-content {
  width: 100%;
  min-height: 900px;
  max-height: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}



.swiper-slide-3f38 {
  background-color: rgba(51, 51, 51, 1);

}

.swiper-slide-3f38c {
 
  background-color: rgba(51, 51, 51, 1);
} 

.swiper-slide-3f38cz {
  background-color: red;
 
}

.manual-slider {
  display: block;
  max-height: unset;
  height:  350px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  overflow-x: scroll;
  margin-top: 20px;
  
  
}

.manual-slider2 {
  display: block;
  max-height: unset;
  height:  640px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  overflow-x: scroll;
  margin-top: 20px;
  
  
}

.manual-slide-container {
  display: inline-block;
  height: 450px;
  width: 500px;
  overflow: hidden;
  margin-right: 2%;
  border-radius: 25px;
  border-radius: 0px;
  text-align: center;

}

.manual-slide-container2 {
  display: inline-block;
  height: 800px;
  width: 500px;
  overflow: hidden;
  margin-right: 2%;
  border-radius: 25px;
  border-radius: 0px;
  text-align: center;

}


.s {
  border-radius: 25px;
  border-radius: 0;
 
}

.slider-image-5 {
  position: relative;
  min-height: 100%;
  min-width: 100%;

  border-radius: 0px;
  
}

.swiper-slide-3f38f {
  height: 250px;
  overflow: hidden;
}

.swiper-slide-content-cd26 {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 0px;
  gap: 0px;

  background: linear-gradient(to bottom, black, rgba(0, 0, 0, 0.804), black);
}
.swiper-slide-text-79be {
  color: rgba(255, 255, 255, 1);
  text-align: center;
  font-size: 22px;
  line-height: 1.5;

  position: absolute;
  width: 100%;
  top: 63%;
  transform: translate(0, -63%);

  text-transform: uppercase;
  font-family: "Sanz-Heavy", sans-serif;
  font-family: "Sanz-Reg-Expd", sans-serif;
  z-index: 99;

}

.swiper-slide-text-79bec {
  color: rgba(255, 255, 255, 1);
  text-align: center;
  font-size: 22px;
  line-height: 1.2;

  position: absolute;
  width: 100%;
  top: 60%;
  transform: translate(0, -60%);

  text-transform: uppercase;
  font-family: "Sanz-Heavy", sans-serif;
  z-index: 99;

}

.swiper-slide-text-f7f1 {
  color: rgba(255, 255, 255, 1);
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  position: absolute;
  top: 71%; transform: translate(0, -90%);
  text-transform: uppercase;
  font-family: "Sanz-Bold", sans-serif;
  color: white;
  z-index: 99;
  padding: 7px;
}

.swiper-slide-text-ffff {
  position: absolute;
  bottom: 5vh;
  text-align: center;
  width: 80%;
  color: rgb(228, 228, 228);
  white-space: normal;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: normal;
  font-family: "Sanz-Thin-Italic", sans-serif;
  z-index: 99;
  margin: 0;
  padding: 0;
  z-index: 99;
  background: radial-gradient(rgba(0, 0, 0, 0.081), transparent);
}

.swiper-slide-image-a4cc {
  max-width: 100%;
  min-width: 0;
  max-height: 100%;
  min-height: 0;
  object-fit: contain;
}
.swiper-slide-block-fe7a {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0px;
  padding: 0px 0px;
}
.swiper-slide-image-22b2 {
  max-width: 100%;
  min-width: 0;
  max-height: 100%;
  min-height: 0;
  object-fit: contain;
}

video.slider-vid {
  width: auto;
  height: 100%;
  object-fit: contain;
  opacity: .8;
  background: radial-gradient(black, transparent);

}

video.slider-vid2 {

  min-width: fit-content;
  min-height: fit-content;
  position: absolute;
  top: 0%; left: 50%; transform: translate(-50%, 0%);
  object-fit: contain;
  opacity: 1;
  background: radial-gradient(black, transparent);

}



img.slider-map {
  background: transparent;
  background-color: transparent;
  opacity: 1;
  position: absolute;
  z-index: 9;
  top: 90%; 
  width: 180px;
  height: auto;
  transform: translate(-90%);
  left: 63%;


}

.slider-map {
  background: transparent;
  background-color: transparent;
  opacity: 1;
  position: absolute;
  z-index: 9;
  top: 90%; 
  width: 180px;
  height: auto;
  transform: translate(-90%);
  left: 63%;


}



img.slider-image {
  height: 100%;
  top: 0;
  position: absolute;
  left: 50%; transform: translate(-50%);
}

.student-swipes {
  height: 50vh;
  overflow: hidden;
}


.swiper-slide-content-2 {
  min-height: 100%;
  height: 500px;
  min-width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.swiper-slide-content7 {
  max-height: 250px;
  height: 250px;
  width: 7%;
  display: block;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
  
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.slider-image-2 {
  min-height: 500px;
  width: auto;
  min-width: 100%;
  position: relative;
  border-radius: 0px;
}

.slider-image-3 {
  min-height: 100%;
  width: auto;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}


.slider-image-4 {
  min-height: 80vh;
  width: auto;
  position: absolute;
  top: 50%; left: 75%;
  transform: translate(-75%, -50%);
}

.slider-image-5 {
  height: auto;

  width: 100%;
  position: relative;

}

.slider-image-6 {

  min-height: 500px;
  width: auto;
  width: 100%;
  position: relative;
}

  
.swiper-slide-content-3 {

  width: 100%;
  height: 300px;
  display: flex;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.swiper-slide-content-6 {
   
 
  min-width: 100%;
  min-height: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}



@media (max-width: 800px) {

  .manual-slider2 {
    display: block;
    max-height: unset;
    height:  54vh;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    overflow-x: scroll;
    margin-top: 20px;
    
    
  }

  .manual-slide-container2 {
    display: inline-block;
    height: 700px;
    width: 80%;
    overflow: hidden;
    margin-right: 2%;
    border-radius: 25px;
    border-radius: 0px;
    text-align: center;
  
  }

  .manual-slider {
    display: block;
    max-height: unset;
    height:  30vh;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    overflow-x: scroll;
    margin-top: 20px;
    
    
  }
  
  .manual-slide-container {
    display: inline-block;
    height: 45vh;
    width: 500px;
    overflow: hidden;
    margin-right: 2%;
    border-radius: 25px;
    border-radius: 0px;
    text-align: center;
  
  }

  .swiper-slide-content-6 {
   
 
    min-width: 100%;
    min-height: 100%;
 
    display: flex;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }

  .slider-image-6 {

    min-height: 100%;
    width: 106%;
    position: relative;
  }
  

  .manual-slider {
    display: block;
    max-height: unset;
    height:  auto;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    overflow-x: scroll;
    margin-top: 20px;
    
  }
  
  .manual-slide-container {
    display: inline-block;
    height: auto;
    width: 88%;
    overflow: hidden;
    margin-right: 2%;
    border-radius: 20px;
    border-radius: 0px;
    
  }
  
  .s {
  
  }
  
  .slider-image-5 {
    position: relative;
    height: 100%;
    width: 80%;
  }

  .left-side {
    float: none;
   
    margin-left: unset;
    margin: 0 auto;
  }
  
  .right-side {
    margin: 200px;
    margin: unset;
    width: 90%;
    margin: 0 auto;
  }

  .full-page {
    margin-top: 50px;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(to bottom, white, black);
  }
  
  :root {
    --swiper-pagination-color: rgba(255, 255, 255, 1);
    --swiper-pagination-bullet-inactive-color: rgba(255, 255, 255, 1);
    --swiper-navigation-color: rgb(255, 255, 255, 1);
  
  }
  
  .swiper {
    user-select: none;
    box-sizing: border-box;
    overflow: visible;
    width: 93.5%;
  
    height: 100%;
    padding: 1px 10px;
  
  }

  .swiper2 {
    user-select: none;
    box-sizing: border-box;
    overflow: visible;
    width: 350px;
    margin-top: 0;
    height: auto;
    padding: 1px 10px;

  }
  

  .swiper.swiper3 {
      min-width: unset;
      max-width: unset;
      width: 90vw;
      height: 250px;
  }
  
  .swiper4 {
    width: 90vw;
    max-width: 380px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80vw;
    max-height: 75vh;
    min-height: 440px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  
  }
  
  .swiper-slide-content {
    width: 100%;
    min-height: 900px;
    max-height: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }

  .student-swipes-2 {
    height: 320px;
    overflow: hidden;
  }

  .swiper-slide-content-2 {
    height: 500px;
    width: auto;
    display: flex;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }
  
  .swiper-slide-content-3 {
    height: auto;
    width: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }

  .swiper-slide-content-5 {
   
    width: auto;
    min-width: 100%;
    height: 250px;
    display: flex;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }

  .swiper-slide-3f38 {
    background-color: rgba(51, 51, 51, 1);
  
  }
  .swiper-slide-content-cd26 {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 0px;
    gap: 0px;
  
    background: linear-gradient(to bottom, black, rgba(0, 0, 0, 0.804), black);
  }
  .swiper-slide-text-79be {
    color: rgba(255, 255, 255, 1);
    text-align: center;
    font-size: 20px;
    line-height: 1.2;
  
    position: absolute;
    width: 100%;
    top: 63%;
    transform: translate(0, -63%);
  
    text-transform: uppercase;
    font-family: "Sanz-Heavy", sans-serif;
    font-family: "Sanz-Reg-Expd", sans-serif;
    z-index: 99;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.685);
  
  }
  
  .swiper-slide-text-79bec {
    color: rgba(255, 255, 255, 1);
    text-align: center;
    font-size: 20px;
    line-height: 1.2;
  
    position: absolute;
    width: 100%;
    top: 61%; transform: translate(0, -61%);
    text-transform: uppercase;
    font-family: "Sanz-Heavy", sans-serif;
    z-index: 99;
  
  }
  
  .swiper-slide-text-f7f1 {
    color: rgba(255, 255, 255, 1);
    text-align: center;
    font-size: 12px;
    line-height: 1.5;
    position: absolute;
    top: 71%; transform: translate(0, -71%);
    text-transform: uppercase;
    font-family: "Sanz-Bold", sans-serif;
    color: white;
    z-index: 99;
    padding: 7px;
  }
  
  .swiper-slide-text-ffff {
    position: absolute;
    top: 91.5%; transform: translate(0, -91.5%);
    text-align: center;
    width: 80%;
    color: rgb(228, 228, 228);
    white-space: normal;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: normal;
    font-family: "Sanz-Thin-Italic", sans-serif;
    z-index: 99;
    margin: 0;
    padding: 0;
    z-index: 99;
    background: radial-gradient(rgba(0, 0, 0, 0.081), transparent);
  }
  
  .swiper-slide-image-a4cc {
    max-width: 100%;
    min-width: 0;
    max-height: 100%;
    min-height: 0;
    object-fit: contain;
  }
  .swiper-slide-block-fe7a {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 0px;
    padding: 0px 0px;
  }
  .swiper-slide-image-22b2 {
    max-width: 100%;
    min-width: 0;
    max-height: 100%;
    min-height: 0;
    object-fit: contain;
  }
  
  video.slider-vid {
    width: auto;
    height: 100%;
    height: 640px;
    min-height: 0;
    object-fit: contain;
    opacity: 1;
    background: radial-gradient(black, transparent);
  
  }
  
  video.slider-vid2 {
  
    min-width: fit-content;
    min-height: fit-content;
    height: 600px;
    position: absolute;
    top: 0%; left: 50%; transform: translate(-50%, 0%);
    object-fit: contain;
    opacity: 1;
    background: radial-gradient(black, transparent);
  
  }
  

  
  img.slider-map {
    background: transparent;
    background-color: transparent;
    opacity: 2;
    position: absolute;
    z-index: 9;
    bottom: 0vh;
    width: 180px;
    height: auto;
    bottom: 0;
    left: 63%;
  
  
  }
  
  .slider-map {
    background: transparent;
    background-color: transparent;
    opacity: 2;
    position: absolute;
    z-index: 9;
    bottom: 0vh;
    width: 50%;
    height: auto;
    bottom: 0;
    left: 63%;
  
  
  }
  

  
  img.slider-image {
    height: 100%;
    top: 0;
    position: absolute;
    left: 50%; transform: translate(-50%);
  }

  .slider-image-2 {
    min-height: 505px;
    width: auto;
    position: relative;
  }

  .slider-image-3 {
    min-height: 80vh;
    width: auto;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }

  .slider-image-4 {
    min-height: 80vh;
    width: auto;
    position: absolute;
    top: 50%; left: 75%;
    transform: translate(-75%, -50%);
  }
  
  
  .student-swipes {
    height: 550px;
    overflow: hidden;
  }
}  