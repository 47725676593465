/* ----------------------------------------------
 * Generated by Animista on 2021-5-25 11:21:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }

  .slide-out-bottom {
	-webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

  .slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s ;
	        animation: slide-in-bottom 0.5s ;
}

.course-info {
    padding-top: 20px;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.4;
    font-size: 10pt;
    font-weight: 500;
}

.course-logo {
    height: 22px;
    width: auto;
    position: absolute;
    top: 4px;
    left: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
 
}

.course-main {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    background-color: rgb(0, 0, 0);
    overflow: hidden;
    padding-bottom: 200px;
}

.fifty {
    display: inline-block;
    margin: 0 auto;
    width: 47%;
    height: 200px;
    text-align: center;
    color: white;
}

.course-section {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    padding: 0px;
    width: 92%;
    height: 90%;
    border-radius: 20px;
    opacity: 1;
    background-color: rgb(0, 0, 0);


}

.course-back-image {
    width: auto;
    height: 120%;
    position: relative;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    opacity: .6;
    z-index: 0;
    border-radius: 20px;
}

.course-section:after {
    position: absolute;
    pointer-events: none;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-image: linear-gradient(-45deg, rgba(24, 23, 23, 0.514), rgba(255, 255, 255, 0.308) 50%, rgba(255, 255, 255, 0.082) 50%);
    -webkit-mask-image: linear-gradient(#000, transparent);
}


.course-section-title {
    position: absolute;
    z-index: 100;
    text-transform: uppercase;
    text-align: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 11pt;
    font-weight: 900;
    opacity: 1;
    color: #ffffff;
    font-family: 'Roboto Condensed', sans-serif;

}

.p-20 {
    padding-bottom: 20px;
    padding-top: 10px;
    font-size: 18pt;
    font-weight: 350;
    background-image: radial-gradient(to center, white, black);
    -webkit-background-clip: text;
    text-align: left;
}

.p-10 {
  padding: 20px;
  padding-top: 0;
  padding-bottom: 10px;
  line-height: 1.4em;
  margin-bottom: 20px;
}

.open-section {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 100000;
    top: 7%;
    background-color: #f0f0f0;
    color: black;
    height: 90%;
    font-size: 11pt;
    font-weight: 300;
    overflow-y: scroll;
    padding-bottom: 100px;
    transition: .6s;
}

.hidden-down {
        position: fixed;
        width: 100%;
        left: 0;
        z-index: 100000;
        top: 100vh;
        background-color: #f0f0f0;
        color: black;
        height: 90%;
        font-size: 11pt;
        font-weight: 300;
        overflow-y: scroll;
        padding-bottom: 100px;
        transition: .6s;
}

.full-overlay {
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transition: .6s ease-in-out;
    opacity: .8;
    background-color: rgba(228, 227, 227, 0.288);
}

.full-overlay i{
    position: fixed;
    top: 2.5%;
    font-size: 16pt;
    z-index: 99999999999999;
    right: 10px;
    opacity: 1;
    color: black;
    background: transparent;
    transition: .6s ease-in-out;


}

.text-box {
    padding-top: 20px;
    width: 100%;
    line-height: 1.6em;
    font-size: 11.5pt;
    font-weight: 400;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 100px;
}


img.inside-text-box {
    padding-top: 5px;
    padding-bottom: 5px;
}

video.inside-text-box {
    width: 100%;

}

h1.crse-title {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 24pt;
    font-weight: 600;
    padding: 10px;
    padding-top: 20px;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #e72f60;
}

p.prelude {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.788);
    padding: 10px;
    padding-bottom: 20px;
    font-style: italic;
}

h1.crse {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16pt;
    font-weight: 300;
    padding: 10px;
    text-transform: uppercase;
    padding-bottom: 20px;
}

p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.788);
    padding: 10px;
    padding-bottom: 20px;
}


p.closing {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.788);
    padding: 10px;
    padding-bottom: 0;
    font-weight: 700;
}

h2.crse {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14pt;
    text-transform: uppercase;
    font-weight: 300;
    color: black;
    padding: 10px;
}

.bloodborne p {
    color: red;
}

.course-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 990;
  left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  background: white;
  color: black;
  text-align: center;
}

.mobile-navbar-container {
  border-top: none;
}

.mobile-banner-left-top {
  position: absolute;
  top: 60px; left: 10px;
  z-index: 9999;
}


.mobile-navbar-link {
  display: inline-block;
  position: relative;
  font-size: 8.5pt;
  font-weight: 280;
  color: black;
  vertical-align: top;
  margin: 0 auto;
  width: 18%;
  opacity: 1;
  text-decoration: none;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.mobile-navbar-link-purp {
  display: inline-block;
  position: relative;
  font-size: 8.5pt;
  font-weight: 400;
  color: #e72f60;
  vertical-align: top;
  margin: 0 auto;
  width: 18%;
  opacity: 1;
  text-decoration: none;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}



.mobile-navbar-link-purp i {
  font-size: 15pt;
  font-weight: 400;
  padding-bottom: 4px;
}

.assignment-link {
  color: #e72f60;
}

.mobile-navbar-link i {
  font-size: 15pt;
  padding-bottom: 4px;
}

i {
  font-size: inherit;
}

.studio-entry-row-title {
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 10.5pt;
  font-weight: 400;
  padding: 0;
  padding-left: 5px;
  padding-bottom: 0px;
  background-color: transparent;
  
}

.assignment-submission {
  border-bottom: 1px solid rgba(255, 255, 255, 0.329);
}

.back-to-home {
  text-transform: uppercase;
  font-size: 10pt;
  font-weight: 550;
  padding-top: 20px;
  padding-left: 20px;
}

.pad-20-t {
  padding-top: 20px;
}
.wht-back {
  color: black;
  background-color: #f7f7f7;
  padding-bottom: 70px;
  overflow-y: scroll;

}

.white-back {
  background-color: white;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.pad-20 {
  padding: 20px;
}

.font {
  font-size: 10pt;
  color: #939393;
  padding-top: 0px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.pad-20 i {
  padding-top: 20px;
}

.welcome {
  position: absolute;
  top: 42%;
  transform: translate(0, -50%);
  background-color: black;
}

.profile {
  position: absolute;
  z-index: 10000000000000000;
  top: 52px;
  right: 0;
  width: 30%;
  text-align: left;
  padding: 20px;
  padding-top: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12pt;
  background-color: black;
  color: white;
}

.profile-p {
  position: absolute;
  z-index: 10000000000000000;
  top: 52px;
  right: 0;
  width: 30%;
  text-align: left;
  padding: 20px;
  padding-top: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12pt;
  background-color: #f7f7f7;
  color: black;
}

.lower-case {
  text-transform: capitalize;
  padding-bottom: 10px;
  margin-bottom: 0;
}

i { font-size: inherit;}

h1 {

}

h1.assignments {
  font-weight: 300;
  padding-bottom: 0;
}

.course-logo-c {
  left: 50%;
  transform: translate(-50%);
  height: 37px;
  padding: 20px;
  width: auto;
  position: absolute;

}

.no-pad {
  padding: 0;
  padding-top: 30px;
  padding-bottom: 20px;
}

.size-up {
  font-size: 42px;
}

.class-wrap {
  background-color: #1a1a1a;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;

  line-height: 1.4em;
  margin-top: 20px;
}

.top-back-btn {
  top: 2%;
  position: fixed;
  text-transform: uppercase;
  color: rgb(78, 78, 78);
  font-size: 9.5pt;
  left: 2%;
}

.class-title {
  font-size: 16pt;
  text-transform: uppercase;
  padding-top: 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  line-height: 1.3em;
  font-weight: 200;
  text-align: center;
  color: rgb(78, 78, 78);
}

.class-title-a {
  font-size: 14pt;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  line-height: 1.3em;
  font-weight: 300;
  text-align: center;
  color: white;
}

.cost {
  padding-top: 11px;
  font-size: 8pt;
  font-weight: 300;
  background-color: white;
}

.row {
  padding: 15px;
  font-size: 11.5pt;
  font-weight: 300;
}

.row-a {
  background-color: #f1f1f1;
}

.row-b {
  background-color: white;
}

.italic {
  font-style: italic;
}

.video-thumb {
  width: 300px;
  border-radius: 25px;
  text-align: center;
  margin: 0 auto;
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  overflow: hidden;
}

.play-button {
  color: white;
  opacity: .4;
  position: absolute;
  top: 50%;
  left: 48%; transform: translate(-50%, -50%);
  transform: translate(0, -50%);
  z-index: 9999999;
  font-size: 70px;
}



@media (max-width: 800px) {

  .play-button {
    color: white;
    opacity: .4;
    position: absolute;
    top: 50%;
    left: 45%; transform: translate(-50%, -50%);
    transform: translate(0, -50%);
    z-index: 9999999;
    font-size: 70px;
  }


  .video-thumb {
    width: 95%;
    border-radius: 25px;
    text-align: center;
    margin: 0 auto;
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  
  }

  .row {
    padding: 15px;
    font-size: 9.5pt;
    font-weight: 300;
  }



  .PMU-details {
    width: 100;
    margin: 0 auto;
  }

  .pmu-details-welcome {
    width: 70%;
    margin: 0 auto;
    text-align: center;
  }

  .welcome {
    width: 100%;
    margin: 0 auto;
    left: 50%;
    top: 23%;
    transform: translate(-50%);
  }


.p700 {
  width: 700px;
  margin: 0 auto;
}

.fifty {
  display: inline-block;
  margin: 0 auto;
  width: 25%;
  height: 200px;
  text-align: center;
  color: white;
}



.course-section:hover {
  cursor: pointer;
}

.p600 {
  width: 600px;
  margin: 0 auto;
}

.purp {
  color: #e72f60;
  background-color: #e72f60;
}
.photo-wrap {
  display: block;
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow-y: scroll;  }

.photo-container {
  display: inline-block;
  position: relative;
  height: 210px;
  overflow: hidden;
}

.photo-container2 {
  display: inline-block;
  position: relative;
  height: 60px;
  overflow: hidden;
}

img.work-sample {
  position: relative;
  height: 100%;
  width: auto;
}
}

.p {
  font-size: 10pt;
}

.uppercase2 {
  font-size: 16pt;
  font-weight: 300;
}

img.course-img {
  padding: 0;
  margin: 0;
  margin-bottom: -5px;
}

.row {
  box-shadow: none;
  border: none;
  border-top: none;
  border-bottom: none;
  font-family: "Sanz-Reg", sans-serif;
  font-size: 9.5pt;
}


.gray-font {
    color: #cecece;
}

.purple-back {
    background: transparent;
    position: absolute;
    top: 56%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: -10;
    box-shadow: 0px 0px 180px 30px #e43bfa;
    opacity: .44;
}

.pink-back {
    background: transparent;
    position: absolute;
    top: 62%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: -10;
    box-shadow: 0px 0px 180px 30px #fa3bd1;
    opacity: .44;
}

.purple-back-frontpg {
    background: transparent;
    position: absolute;
    top: 56%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #e43bfa;
    opacity: .44;
}

.pink-back-frontpg {
    background: transparent;
    position: absolute;
    top: 62%;
    width: 60%;
    left: 50%; transform: translate(-50%);
    display: block;
    z-index: 0;
    box-shadow: 0px 0px 180px 30px #fa3bd1;
    opacity: .44;
}

.inkbrush-image {
    width: unset;
    left: unset; transform: unset;
    margin-top: -15%;
    position: relative;
}

.fade-in-and-out {
    -webkit-animation: fade-in-and-out 4s ease-in-out infinite;
    -moz-animation: fade-in-and-out 4s  ease-in-out infinite;
    animation: fade-in-and-out 4s  ease-in-out infinite;
}

.fade-in-and-out2 {
    -webkit-animation: fade-in-and-out2 4s  ease-in-out infinite;
    -moz-animation: fade-in-and-out2 4s  ease-in-out infinite;
    animation: fade-in-and-out2 4s  ease-in-out infinite;
}

.height-60 {
    height: 60vh;

}

.apple-gray-back {
    background: #161617;
    width: 100%;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 140px;
    text-align: center;
    font-size: 28px;
    line-height: 1.6em;
    font-family: "Sanz-Thin", sans-serif;
}

.ink-gradient {
    color: transparent;
    background: linear-gradient(to right, #eb4191, #7c4a8a);
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 29px;
    line-height: 1.6em;
    font-family: "Sanz-Thin", sans-serif;
}


.deeper-gray-font {
   color: #86868b;
   font-family: "Sanz-HeavyCond", sans-serif;
   line-height: 1.4285914286;
   letter-spacing: -0.016em;
   font-size: 16px;
   font-weight: 400;
}

.sanz-thin {
    font-family: "Sanz-Thin", sans-serif;
}

.inkbrush-text {
    position: absolute;
    left: 50%; transform: translate(-50%);
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
}

.sanz-regitalic {
    font-family: "Sanz-RegItalic", sans-serif;
}

a.purp-btn, a.purp-btn i {
    background: none;
    color: #7b4397;
    margin: 0 auto;
    text-transform: none;
    font-size: 16px;
    width: fit-content;
    font-weight: 400;
    border-radius: 0;
    margin-bottom: 20px;
}

button.purp-btn i { 
    font-weight: 400;
}

.no-caps {
    text-transform: none;
}

.abs-right {
    float: right;
    font-size: 16px;
}

.border-top-bottom {
    border-bottom: .5px solid #3a3939;


}

.photo-wrap {
  display: block;
  position: relative;
  width: 100%;
  margin-top: 0px;
  white-space: nowrap;
  overflow-y: scroll;  
  padding-bottom: 30px;}

  .photo-wrap2 {
    display: block;
    position: relative;
    width: 100%;
    padding-left: 20px;
    margin-top: 0px;
    white-space: nowrap;
    overflow-y: scroll;  
    padding-bottom: 10px;
    padding-top: 0px;}


.photo-container {
  display: inline-block;
  position: relative;
  height: 210px;
  width: auto;
  margin: 5px;
  overflow: hidden;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.photo-container2 {
  display: inline-block;
  position: relative;
  height: 50px;
  width: auto;
  margin: 2px;
  overflow: hidden;
  border-radius: 8px;
 
}

img.work-sample {
  position: relative;
  height: 100%;
  width: auto;
}

.in-course {
  padding-left: 10px;
  color: rgb(78, 78, 78);
  margin-bottom: 20px;
}


.sanz-thin {
  font-family: "Sanz-Thin", sans-serif;
}

.apple-h2 {
  color: #cecece;
  color: white;
  display: inline-block;
  max-width: unset;
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  pointer-events: none;
  font-size: 62px;
  line-height: .9;
  font-weight: 600;
  letter-spacing: -.03em;
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  color: transparent;
  background: linear-gradient(to right, #eb4191, #7c4a8a);
  background-clip: text;
  -webkit-background-clip: text;
  width: fit-content;
  background-size: cover;
  text-transform: uppercase;
  background-size: auto, auto;
  text-align: center;
  margin-top: 20px;
  left: 50%; transform: translate(-50%);
}

.apple-h3 {
  color: #cecece;
  color: #1e1b20;
  color: #cecece;
  text-align: center;
  max-width: 57.6470588235rem;
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  pointer-events: none;
  font-size: 55px;
  line-height: .51;
  font-weight: 600;
  letter-spacing: -.03em;
  font-synthesis: none;
  text-transform: uppercase;
  -moz-font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  margin-top: 75px;
  left: 50%; transform: translate(-50%);
}


.inkbrush-text {
  position: absolute;
  margin-top: 210px;
  left: 50%; transform: translate(-50%);
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 0;
  color:rgba(252, 252, 252, 0.823);
  text-align: center;
}

.sanz-regitalic {
  font-family: "Sanz-RegItalic", sans-serif;
}


.apple-h2a {
  color: #cecece;
  color: white;
  display: inline-block;
  max-width: unset;
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  pointer-events: none;
  font-size: 28px;
  line-height: .9;
  font-weight: 600;
  letter-spacing: -.03em;
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  color: transparent;
  background: linear-gradient(to right, #eb4191, #7c4a8a);
  background-clip: text;
  -webkit-background-clip: text;
  width: fit-content;
  background-size: cover;
  text-transform: uppercase;
  background-size: auto, auto;
  text-align: center;
  margin-top: 38px;
  left: 44%; transform: translate(-50%);
}

.apple-h3a {
  color: #cecece;
  color: #1e1b20;
  text-align: center;

  
  z-index: 3;
  pointer-events: none;
  font-size: 20px;
  position: absolute;
  font-weight: 600;

  font-synthesis: none;
  text-transform: uppercase;
  -moz-font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  margin-top: 20px;
  left: 44%; transform: translate(-50%);

}

.product-details {
  font-size: 13px;
  text-align: center;
  margin-top: -40px;
}

.product-details a{
  font-size: 13px;
  color: #7b4397;
  text-align: center;
}

.width-90 {
  
}

.pad-l-10 {
  padding-left: 10px;
}



.c {
  position: relative;
}


