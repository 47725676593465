
* {
  -webkit-font-smoothing: antialiased;
}

.large-navbar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to right, white, #9a44b27a, #9a44b2cb);
  background: linear-gradient(to left, white, white, rgb(245, 245, 245), rgb(243, 243, 243), rgb(248, 248, 248), #9a44b28f);
  background: transparent;
  overflow: hidden;
  z-index: 99;
  background-size: 100% 100%;
}

.inside-navbar {
  padding-bottom: 0px;
}


.l {text-align: left;}

.back-nav {
  background: white
}

.abs-c {
  display: block;
  width: 100%;
  height: fit-content;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}

.width-90 {
  width: 90%;
  text-align: center;
}
.marg-20 {
  margin-top: -80px;
}

img.logo {
    display: block;
    position: absolute;
    padding-top: 0%; left:4.5%;
    height: auto;
    width: auto;
    z-index: 999999;
    width: 90%;
}

img.logo.blk {
  display: block;
  position: absolute;
  padding-top: 2%; left:1.5%;
  height: auto;
  width: auto;
  z-index: 999999;
  width: 100%;
}

.half {
  display: inline-block;
  height: 100vh;
  width: 50%;
}


.block {
  width: 100%;
  margin: 0 auto;
  height: 90vh;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  text-align: left;
  display: block;
  position: relative;
}

.block-inside {
  position: absolute;
  display: block;
  width: 96%;
  overflow: hidden;
  height: 96%;
  background: radial-gradient(to center, white, #f5f5f5);
  left: 50%; top: 50%; transform: translate(-50%, -50%);
}

.block-inside.full {
  width: 100%;
  height: 100%;
}

.page {
  margin-top: 100px;
}

a {
    text-decoration: none;
}

.links {
    position: absolute;
    z-index: 1000;
    top: 12px;
    right: 40px;
    color: white;
    text-align: right;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 9pt;
    z-index: 999999999999999999;
}

.login {
    position: relative;
    width: 100%;
    right: 20px;
}

.login-main {
    padding-right: 20px;
}

.login-main:hover {
    cursor: pointer;
}

.login-links {
    background-color: rgba(0, 0, 0, 0.363);
    margin-top: 20px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.151);
    transition: .6s;
}

.font-30 {
  font-size: 18pt;
}

a.login-link {
    padding: 15px;
    display: block;
    color: black;
    margin-top: 1px;
    background-color: rgba(0, 0, 0, 0.363);
    background-color: rgba(255, 255, 255, 0.842);
}

.login-main-wht {
    padding-right: 20px;
}

a.login {
    position: relative;
    color: white;
    right: 0px;
    top: 15px;
    font-weight: 300;
    z-index: 100;
}

.full-navbar {
    background-color: rgb(0, 0, 0);
    padding: 22px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}
a.logout {
    color: black;
    position: absolute;
    right: 0;
    margin-top: 12px;
    z-index: 10000;
  }

  .back-btn {
      position: fixed;
      top: 5px;
      left: 5px;
      z-index: 99999999999;
      font-size: 35px;
      color: rgba(78, 78, 78, 0.199);
      color: #e4ccc6a9;
      color: rgba(128, 128, 128, 0.733);
  }



  .hamburger-a {
    display: block;
    width: 32px;
    height: 1.5px;
    margin-top: 4px;
    background: white;
  }

  .hamburger-b {
    display: block;
    width: 26px;
    height: 1.5px;
    margin-top: -7px;
    background: white;
  }

  .hamburger-c {
    display: block;
    width: 20px;
    height: 2px;
    background: black;
  }



  span.hamburger {
    display: block;
    position: relative;
    height: 1px;
    border-radius: 9px;
    opacity: 1;
    width: 30px;
    transition: .4s;

  }

  .rotate-45l {
    transform: rotate(-45deg) translate(-8px, 8px); 
    transition: .4s;
    background-color: white;
    z-index: 9999999999;
  }

  .rotate-45r {
    transform: rotate(45deg) translate(3px, 2px);
    transition: .4s;
    background-color: white;
    z-index: 9999999999;
  }

  .bottom-navbar2 {
    position: fixed;
    width: 100%;
    height: 60px;
    background: linear-gradient(white, white, white, white);
    z-index: 999;
    bottom: 0;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    background: linear-gradient(white, rgba(255, 255, 255, 0.813), rgba(255, 255, 255, 0.814), white);
    z-index: 999;
    bottom: 0;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }

  .bottom-navbar-btn {
    color: white;
    text-transform: uppercase;
    padding: 12px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 25px;
    margin: 7px;
    font-size: 11pt;
    font-family: poppins, sans-serif;
    font-weight: 600;
    margin: 10px auto;
    margin-right: 5px;
    margin-left: 5px;
    vertical-align: bottom;
    display: inline-block;

  }

  .bottom-navbar-btn:hover {
    cursor: pointer;
  }


  .bottom-nav-box {
    height: 120vh;
  }

  .mobile-navbar {
    display: block;
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    text-align: center;
    padding-top: 18%;
    font-size: 14pt;
  } 
  .course-nav-p .hamburger a {
      color: #3a0a17;
  }

  .course-nav {
      background-color: black;
      position: fixed;
      top: 0;
      width: 100%;
      height: 52px;
      z-index: 9999;
  }

  .course-nav-p {
    background: linear-gradient(to left, white, #c282e2cc, black) ;
    background: linear-gradient( to right, #e72f6088 , #7b439791 );
    position: fixed;
    top: 0;
    width: 100%;
    height: 52px;
    z-index: 99999999999;
}

.nav-btn {
    display: inline-block;
    padding-right: 2%;
    padding-top: 2%;
    
  }

  .navbar {
    position: fixed;
    top: -1px;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    background: linear-gradient(to right, rgba(82, 82, 82, 0.973), rgba(117, 117, 117, 0.582), rgba(255, 255, 255, 0.219));
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  }
  
  .navbar-left {
    display: inline-flex;
    padding-left: 2%;
    vertical-align: middle;
    width: 28%;
  }

  .relative {
    position: relative;
  }

  .mobile-navbaar-right {
    display: none;
  }



  .mobile-navbaar-right {
    display: block;
  }

    .navbar-right {
      
        padding-right: 0%;
        display: block;
        text-align: right;
        float: right;
        vertical-align: middle;
        width: 70%;
        color: white;
        font-family: "Sanz-Bold", sans-serif;
        text-transform: uppercase;
        margin-top: 2.2%;
        margin-right: 1.5%;
        z-index: 99999999;
    
      }

      a.desktop-navbar-link {
        color: white;
        font-family: "Sanz-Reg", sans-serif;
        font-size: 9pt;
        font-weight: 500;
        padding: 10px;
        padding-top: 0; padding-bottom: 0;
        letter-spacing: -.2px;
        border-right: .5px solid rgba(255, 255, 255, 0.492);
        z-index: 9999999;
      }

      .right-navbar {
        display: inline-block;
        text-align: right;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 20px;
        width: 68%;
        vertical-align: middle;
      }

      .large-navbar-link {
        display: inline-block;
        position: relative;
        padding-right: 40px;
        vertical-align: middle;
        text-transform: uppercase;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 400;
        color: black;
        letter-spacing: .5px;
        font-size: 12pt;
      }

      .section {
        width: 100%;
        height: 50vh;

      }



  

      span.hamburger {
        display: block;
        position: relative;
        height: 1.5px;
        margin: 7.5px;
        border-radius: 9px;
        opacity: 1;
        width: 30px;
        transition: .4s;
        background-color: rgb(14, 14, 14);
      }
    

    
      .mobile-navbar {
        display: block;
        position: fixed;
        top: 0; left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.973);
        text-align: center;
        padding-top: 15%;
        z-index: 9;
        font-size: 10pt;
      }
    
      
    


      .mobile-navbar-right {
        display: block;
        position: fixed;
        top: 1.5%; right: 0%;
        z-index: 100;
    
      }
    
      .pad-20 {
        padding: 10px;
      }

      .nav-btn {
        display: block;
        margin: 0 auto;
        padding: 8%;
        font-size: 10pt;
    
      }


.bolder {
    font-weight: 400;
}



.hamburger.rotate-45l {
  transform: rotate(-45deg) translate(-8px, 8px); 
  transition: .4s;
  background-color: white;
  z-index: 9999999999999;
}

.hamburger.rotate-45r {
  transform: rotate(45deg) translate(3px, 2px);
  transition: .4s;
  background-color: white;
  z-index: 999999999999999;
}

.display-none {
  display: none;
}



.nav-btn-wrapper-inside {
 

}

a.nav-btn-block {
  background: rgba(112, 112, 112, 0.397);
  margin: 4px;
  border-radius: 20px;
  padding-left: 12px;
  padding-right: 12px;

  padding-top: 4px; padding-bottom: 4px;
  box-shadow: rgba(93, 50, 89, 0.212) 0px 50px 100px -20px, rgba(0, 0, 0, 0.24) 0px 30px 60px -30px, rgba(64, 10, 52, 0.267) 0px -2px 6px 0px inset;
  box-shadow: rgba(46, 12, 43, 0.15) 0px 48px 100px 0px;
}

a.nav-btn-block.z {
  background: rgba(24, 24, 24, 0.377);
  margin: 4px;
  border-radius: 20px;
  padding-left: 12px;
  padding-right: 12px;

  padding-top: 4px; padding-bottom: 4px;
  box-shadow: rgba(93, 50, 89, 0.212) 0px 50px 100px -20px, rgba(0, 0, 0, 0.24) 0px 30px 60px -30px, rgba(64, 10, 52, 0.267) 0px -2px 6px 0px inset;
  box-shadow: rgba(46, 12, 43, 0.15) 0px 48px 100px 0px;
}

.fade-in a.nav-btn-block {
  
}

.nav-btn-block {

}

.nav-btn-block:hover {
  cursor: pointer;
}

.nav-btn-block-inside {
  background: radial-gradient(to center, rgb(129, 129, 129), rgb(24, 24, 24), rgb(131, 131, 131));
  padding-top: 0px;
  font-weight: 800;
  padding: 4px;
  padding-left: 0;
  padding-right: 0;
  letter-spacing: -0.5px;
  background-clip: text;
  -webkit-background-clip: text;
  color: white;
}



  .full-mobile-nav {
    height: 100vh;
    width: 70%;
    background-color: white;
    position: fixed;
    top: 100px;
    right: 0;
    padding-left: 20px;
    z-index: 999999999;
    overflow-y: scroll;
  }

  .full-back-nav {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.808);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999999;
  }

  .abs-nav {
    position: fixed;
    bottom: 6vh;
    right: 20px;
    background-color: #ffffff;
    padding: 12px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 50%;
    text-align: center;
    color: black;
    z-index: 99999999999999999999999999999999999999999999999999999999999;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: .5s;
  }



  .abs-nav i {
    font-size: 16pt;
    position: absolute;
    top: 54%; left: 50%; 
    font-weight: 300;
    transform: translate(-50%, -50%);
    z-index: 999999999999999999999999999999999;
  }

  .abs-navx i {
    font-size: 16pt;
    position: absolute;
    top: 54%; left: 50%; 
    font-weight: 300;
    transform: translate(-50%, -50%);
    z-index: 999999999999999999999999999999999;
  }

  .logo-container {
    display: block;
    position: fixed;
    top: 8px;
    left: 0;
    width: 30%;
    height: 60px;
    background: transparent;
    z-index: 999999;


  }

  .logo-container.fixed {
    display: block;
    position: fixed;
    top: 2%;
    left: 10px;
    width: 30%;
    background: transparent;
  }



  .nav-btn-wrapper {
    display: block;
    position: relative;
    text-align: right;
    padding-top: 10px;
    padding-right: 10px;
    background: linear-gradient(196deg, rgba(0, 0, 0, 0.514), transparent, transparent);
    background: none;
  }

.nav-btn-wrapper-inside {

 
}

.nav-btn-block {

  width: fit-content;
  margin: 4%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 10pt;
  text-align: center;
  background-color: transparent;
  text-transform: uppercase;
  overflow: hidden;
  display: inline-block;
  background: linear-gradient(to right, rgba(79, 40, 87, 0.973),  rgb(241, 42, 118));
  background: radial-gradient(to center, rgb(129, 129, 129), rgb(24, 24, 24), rgb(131, 131, 131));
  padding-top: 0px;
  font-weight: 500;
  letter-spacing: -0.5px;
  background: transparent;
  color: transparent;
}

.nav-btn-block:hover {
  cursor: pointer;
}

.nav-btn-block-inside {
  background: radial-gradient(to center, rgb(129, 129, 129), rgb(24, 24, 24), rgb(131, 131, 131));
  padding-top: 0px;
  font-weight: 500;
  padding: 4px;
  padding-left: 0;
  padding-right: 0;
  letter-spacing: 0px;
  background-clip: text;
  -webkit-background-clip: text;

}

.nav-btn-block-inside.highlight {
  color: red;
}

.bottom-nav {
  position: relative;
  bottom: 0;
  background-color: #ececec;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 11.5pt;
  padding: 20px;
  padding-bottom: 100px;
}

img.bottom-nav-img {
  width: 80%;
}

.bottom-nav-buttons {
  text-align: center;

}

.bnbt {
  font-size: 12pt;
  padding: 10px;
  padding-top: 30px;
  font-weight: 600;
  color: black;
  font-family: "Sanz-Thin", sans-serif;
 
}

.back-radial-gradient {
  background: radial-gradient(rgba(0, 0, 0, 0.406), transparent);
}

.back-radial-gradient-2 {
  background: radial-gradient(rgba(0, 0, 0, 0.381), transparent);
}

.bnb {
  display: block;
  line-height: 1.6em;
  padding-left: 10px;
  font-size: 9pt;
  transition: .5s;
 
}


.bnba {
  display: block;
  line-height: 1.6em;

  font-size: 11pt;
  color: black;

}


.social-links {
  padding-top: 20px;
}

.social-links i {
  font-size: 22pt;
  padding: 20px;
  padding-bottom: 0;
}

span.arrow {
  font-size: 16pt;
  padding-right: 7px;
}

a {
  text-decoration: none;
}



button.bnb {
  color: rgb(34, 30, 34);
  display: block;
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: left;
  transition: .5s;
}

button.bnbt {
  display: block;
}

span.assist {
  color: rgba(97, 97, 97, 0.678);

}
  
.spacers {
  padding: 15px;
  padding-left: 10px;

}

.spacers2 {
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;


}



.social-links a {
  color: rgba(0, 0, 0, 0.904);
}

.course-title-in-list {
  position: relative;
  top: 10px;
}

.course-list-item {
  margin-top: 0px;
  padding-top: 0;
  height: 260px;
  position: relative;
  background: white;
  width: 100%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.582) 0px 3px 8px;

}

img.mini {
  width: 32%;
  height: auto;
  position: absolute;
  margin-top: -14px;
  display: block;
  right: 5%;
  transform: rotate(-90deg);
  padding: 0;
  vertical-align: top;
}

.bottom-40 {
  padding-bottom: 40px;
}

.div-spacer {
  padding-top: 60px;
  border-top: 1px solid rgba(0, 0, 0, 0.185);
  background: linear-gradient(5deg, rgba(64, 36, 75, 0.841), rgba(255, 255, 255, 0.928), rgba(255, 255, 255, 0.698));
  background-size: 300% 300%;

  -webkit-animation: WelcomePageGradient 5s ease infinite;
  -moz-animation: WelcomePageGradient 5s ease infinite;
  animation: WelcomePageGradient 5s ease infinite;
}

.learn-btn {
  color: #575757;
  font-size: 8pt;
  position: absolute;
  bottom: 15px;
  left: 10px;
}

.learn-btn span.arrow {
  font-size: 10pt;
}

iframe.map {
  width: 100%;
  text-align: center;
  border: 0;
  margin: 0 auto;
  margin-bottom: -6px;
  height: 600px;
}

.lowercase {
  text-transform: lowercase;
}

.abs-navx {
  position: fixed;
  top: 100px;
  right: 75%;
  background-color: transparent;

  border-radius: 50%;
  text-align: center;
  color: white;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999;
}

img.artist {
width: 100%;

margin: 0 auto;


}

img.artist.o {
  margin: unset;
  text-align: right;

}

img.artist.s {
  text-align: left;

}

.artist-box {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 94%;
  
  margin: 0 auto;
  text-align: left;
  font-size: 14px;
  line-height: 1.4em;
  color: rgba(0, 0, 0, 0.788);
  text-transform: none;
}

.abs-title {
  position: absolute;
  margin-top: 40px;
  float: right;
}

.abs-title-r{
  position: absolute;

  margin-top: 40px;
}

.zindex {
  position: absolute;
  z-index: 99999999;
}

.col {
  display: block;
  padding-bottom: 0px;
  width: 90%;
  margin: 40px auto;
  vertical-align: unset;
}

.courses-page.desktop-view {
  position: relative;
  overflow: hidden;
 width: 400px;
}

.col-25 {
  display: inline-block;
  width: 25%;
  vertical-align: top;
  margin: 0 auto;
  text-align: left;

}

.email-financing {
  text-align: left;
  margin-left: 1%;
  margin-bottom: 40px;
}


a .regular {
  margin-top: 10px;
}

.navbar-right-fixed {
    
  padding-right: 0%;
  display: block;
  text-align: right;
  float: right;
  vertical-align: middle;
  width: 65%;
  color: black;
  font-family: "Sanz-Bold", sans-serif;
  text-transform: uppercase;
  margin-top: 2.2%;
  margin-right: -3.5%;
  z-index: 99999999;

}

.scrollable-row.b {
  position: fixed;
}

.desktop-only {
  position: fixed;
  top: 0%;
}

.large-navbar.navbar-white {
    position: fixed;
    top: 0;
    width: 100%;
    height: 105px;
    overflow: hidden;
    background: linear-gradient(to right, white, #9a44b27a, #9a44b2cb);
    background: linear-gradient(to left, white, white, rgb(245, 245, 245), rgb(243, 243, 243), rgb(248, 248, 248), #9a44b28f);
    background: white;
    z-index: 9999999999999999;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    background-size: 100% 100%;
    transition: .7s;
   
}

video {

}
.paragraph-title.veer-right {
  width: 100%;
  text-align: center;
}

.inline-paragraph.veer-right {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding-left: 10%;
}

.veer-right {
  width: 50%;
}

.veer-right.reg {
  text-transform: none;
  margin-top: -30px;

}

.right-btn {
  position: fixed;
  right: 40px;
  bottom: -2px;
  z-index: 99999999999999999999999;
}

.bottom-course-nav {
  height: 60px;
  background: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999999999;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.left-btn {
  position: absolute;
  left: 20px;
  top: 0;
}

.backtomenu {
  padding-top: 80px;
}


.title {
  font-family: "Sanz-Thin-Reg", sans-serif;
 
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  font-size: 18px;    
  font-weight: 100;

  line-height: 1em;
  letter-spacing: .7px;


}

.title.wht.c {

  position: absolute;
  top: -200px;
  font-family: "Sanz-Thin-Reg", sans-serif;

}


.title.wht.c.studio {

  text-align: left;
  position: absolute;
  top: 40px; left: 50%;
  transform: translate(-50%);
  z-index: 99;
  font-family: "Sanz-Bold-Expd", sans-serif;
  color: white;
  font-weight: 500;
  font-size: 20px;
  text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.644);
  letter-spacing: 1.5px;
}

.under-title {

  text-align: left;
  position: absolute;
  top: 25px;
  left: 27px;

  z-index: 99;
  text-transform: none;
  white-space: unset;
  font-family: "Sanz-Reg", sans-serif;
  color: white;
  font-weight: 500;
  font-size: 7.5pt;
  line-height: 1.04em;
  text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
  letter-spacing: .4px;
  width: 90%;
  white-space: wrap;

  position: absolute;
  left: 45%;
  transform: translate(-50%);
}

.text-shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.888);
}


.moving-grad {
  
  font-family: "Sanz-Reg-Expd", sans-serif;
  background: linear-gradient(to left,#772dac, #f43ea8);



  margin-top: -10px;
  margin-left: -1px;
  margin-right: -4px;
  letter-spacing: 0px;
  background-clip: text;
  font-weight: 400;
  -webkit-background-clip: text;
  -webkit-font-smoothing: antialiased;
  color: transparent;
  font-size: 36px;
  letter-spacing: 1px;
  vertical-align: top;
  text-shadow: none;
  

}     

span.menu-name {
  display: inline-block;
  position: absolute;
  right: 78px;
  top: 42px;
  color: white;
  text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
  z-index: 999;
}

.menu-name:hover {
  cursor: pointer;
}

span.hamburger-box {
  position: absolute;
  right: 45px;
  top: 50px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 999999;
}

span.hamburger-inner {
  right: 0px;
  width: 22px;
  height: 2px;
  background-color: white;
  position: absolute;
  z-index: 99;
  transition-duration: .15s;
  transition-property: transform;
  display: block;
  line-height: 0;
    pointer-events: all;
}

span.hamburger-inner::before {
  top: 8px;
  position: absolute;
  transition-property: transform;
  transition-timing-function: ease;
  transition-duration: .15s;
  box-sizing: border-box;
  width: 22px;
  height: 2px;
  background-color: white;
  z-index: 99;
}

.machine-drop {
  display: inline-block;
  top: 44vh;
  left: 8vw;
  position: absolute;
  width: 85px;
  z-index: 999;

}

button.hamburger {
  position: fixed;
  top: 0px;
  right: 0px;
  height: 200px;
  z-index: 99999999999;
}

.left-words {
  font-size: 20px;
  padding-top: 8px;
  text-transform: uppercase;
  padding-left: unset;
  padding-right: 250px;
  text-align: right;
  line-height: 1.2em;
}

.left-words-2 {
  font-size: 12px;;
}

span.line {
  display: inline-block;
  height: 1px;
  position: relative;
  background-color: black;
  width: 24px;
  vertical-align: middle;
  margin-right: 4px;
}

span.line.white-back {
  background-color: white;
}



@media (max-width: 800px) {

  .left-words {
    font-size: 18px;
    padding-top: 8px;
    text-transform: uppercase;
    padding-left: 20px;
    line-height: 1.2em;
    padding-right: unset;
    text-align: left;
  }
  
  .left-words-2 {
    font-size: 12px;;
  }
  

  .machine-drop {
    display: inline-block;
    top: 38vh;
    left: 20px;
    position: absolute;
    width: 85px;
    z-index: 999;
  
  }
  

  button.hamburger {
    position: fixed;
    top: 0px;
    right: 0px;
    height: 200px;
    z-index: 99999999999;
  }

  span.hamburger-box {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 999999;
  }

  span.menu-name {
    display: inline-block;
    position: absolute;
    right: 58px;
    top: 23px;
    color: white;
    text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
  }

  .under-title {

    text-align: left;
    position: absolute;
    top: 25px;
    left: 27px;

    z-index: 99;
    text-transform: none;
    white-space: unset;
    font-family: "Sanz-Reg", sans-serif;
    color: white;
    font-weight: 500;
    font-size: 7.5pt;
    line-height: 1.04em;
    text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
    letter-spacing: .4px;
    width: 90%;
    white-space: wrap;

    position: absolute;
    top: 25px; left: 45%;
    transform: translate(-50%);
  }

  .title {
    font-family: "Sanz-Thin-Reg", sans-serif;
   
    text-transform: uppercase;
    color: rgb(0, 0, 0);
    font-size: 18px;    
    font-weight: 100;
  
    line-height: 1em;
    letter-spacing: .7px;
  
  
  }
  
  .title.wht.c {
 
    position: absolute;
    top: -200px;
    font-family: "Sanz-Thin-Reg", sans-serif;
  
  }
  
  
  .title.wht.c.studio {
  
    text-align: left;
    position: absolute;
    top: 18px; left: 10px;
    transform: translate(0%);
    z-index: 9999;
    font-family: "Sanz-Black", sans-serif;

    color: white;
    font-weight: 500;
    font-size: 16px;
    text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.644);
    letter-spacing: .5px;
    -webkit-font-smoothing: antialiased;
    --font-family-sans-serif: "Arimo",sans-serif;
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  }


  .moving-grad {
    
    font-family: "Sanz-Reg-Expd", sans-serif;
    background: linear-gradient(to left,#772dac, #f43ea8);

 

    margin-top: -10px;
    margin-left: -1px;
    margin-right: -2px;
    letter-spacing: 0px;
    background-clip: text;
    font-weight: 400;
    -webkit-background-clip: text;
    -webkit-font-smoothing: antialiased;
    color: transparent;
    font-size: 36px;
    letter-spacing: .4px;
    vertical-align: top;
    text-shadow: none;
    

}     
  

  .right-btn {
    position: fixed;
    right: 10px;
    bottom: 0px;
    z-index: 99999999999999999999999;
  }

  .left-btn {
    position: absolute;
    left: 10px;
    top: 0;
  }



  .paragraph-title.veer-right {
    width: 100%;
    padding-left: 28px;
    text-align: left;
  }

  .veer-right {
    width: 70%;
    margin-left: 20px;
    text-align: left;
  }

  .veer-right.reg {
    text-transform: none;
    margin-top: -30px;
    padding-left: 20px;
    text-align: left;
  }

  .large-navbar.navbar-white {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background: white;
    z-index: 9999999999999999;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
 
}

  .bottom-navbar2 {
    position: fixed;
    width: 100%;
    height: 60px;
    background: linear-gradient(white, rgba(255, 255, 255, 0.813), rgba(255, 255, 255, 0.814), white);
    z-index: 999;
    bottom: 0;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }

  .bottom-navbar-btn {
    color: white;
    text-transform: uppercase;
    padding: 11px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 25px;
    margin: 7px;
    font-size: 10pt;
    font-family: "Sanz-Bold", sans-serif;
    font-family: poppins, sans-serif;
    font-weight: 700;
    margin: 10px auto;
    margin-right: 5px;
    margin-left: 5px;
    vertical-align: bottom;
    display: inline-block;
    transition: .3s;
  }




  .col-25 {
    display: block;
    width: unset;
    vertical-align: top;
    margin: 0 auto;
  }

  .courses-page.desktop-view {
    width: unset;
  }


  .col {
    display: block;
    padding-bottom: 0px;
    width: 94%;
    margin: 0 auto;
    vertical-align: unset;
    font-size: 10pt;
  }

.large-navbar {
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;


  z-index: 99;

}

.back-nav {
  
}


.abs-c {
  display: block;
  width: 100%;
  height: fit-content;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}

.width-90 {
  width: 90%;
  text-align: center;
}
.marg-20 {
  margin-top: -80px;
}





.block {
  width: 100%;
  margin: 0 auto;
  height: 90vh;
  position: relative;
  background: white;
  overflow: hidden;
  vertical-align: middle;
  text-align: left;
  display: block;
  position: relative;
}

.block-inside {
  position: absolute;
  display: block;
  width: 96%;
  overflow: hidden;
  height: 96%;
  background: radial-gradient(to center, white, #f5f5f5);
  left: 50%; top: 50%; transform: translate(-50%, -50%);
}

.block-inside.full {
  width: 100%;
  height: 100%;
}

.page {
  margin-top: 100px;
}

a {
    text-decoration: none;
}

.links {
    position: absolute;
    z-index: 1000;
    top: 12px;
    right: 40px;
    color: white;
    text-align: right;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 9pt;
    z-index: 999999999999999999;
}

.login {
    position: relative;
    width: 100%;
    right: 20px;
}

.login-main {
    padding-right: 20px;
}

.login-main:hover {
    cursor: pointer;
}

.login-links {
    background-color: rgba(0, 0, 0, 0.363);
    margin-top: 20px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.151);
    transition: .6s;
}


a.login-link {
    padding: 15px;
    display: block;
    color: black;
    margin-top: 1px;
    background-color: rgba(0, 0, 0, 0.363);
    background-color: rgba(255, 255, 255, 0.842);
}

.login-main-wht {
    padding-right: 20px;
}

a.login {
    position: relative;
    color: white;
    right: 0px;
    top: 15px;
    font-weight: 300;
    z-index: 100;
}

.full-navbar {
    background-color: rgb(0, 0, 0);
    padding: 22px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}
a.logout {
    color: black;
    position: absolute;
    right: 0;
    margin-top: 12px;
    z-index: 10000;
  }

  .back-btn {
      position: fixed;
      top: 5px;
      left: 5px;
      z-index: 99999999999;
      font-size: 35px;
      color: rgba(78, 78, 78, 0.199);
      color: #e4ccc6a9;
      color: rgba(128, 128, 128, 0.733);
  }



  .hamburger-a {
    display: block;
    width: 22px;
    height: .5px;
    background: white;
    margin-bottom: 5px;
    text-align: right;
    margin-top: unset;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
  }

  .hamburger-b {
    display: block;
    width: 30px;
    height: .5px;
    background: white;
    margin-bottom: 5px;
    margin-top: unset;
    text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);

  }

  .hamburger-c {
    display: none;
    width: 22px;
    height: 2px;
    background: white;
    text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
  }

  .hamburger-a2 {
    display: block;
    width: 22px;

    height: 1px;
    background: black;
    margin-bottom: 5px;
    text-align: right;
  }

  .hamburger-b2 {
    display: block;
    width: 30px;
    height: 1px;
    background: black;
    margin-bottom: 5px;

  }

  .hamburger-c2 {
    display: none;
    width: 22px;
    height: 2px;
    background: black;
  }



  span.hamburger {
    display: block;
    position: relative;
    height: 1px;
    border-radius: 9px;
    opacity: 1;
    width: 30px;
    transition: .4s;
    text-align: right;

  }

  .rotate-45l {
    transform: rotate(-45deg) translate(-8px, 8px); 
    transition: .4s;
    background-color: white;
    z-index: 9999999999;
  }

  .rotate-45r {
    transform: rotate(45deg) translate(3px, 2px);
    transition: .4s;
    background-color: white;
    z-index: 9999999999;
  }

  .bottom-nav-box {
    height: 120vh;
  }

  .mobile-navbar {
    display: block;
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    text-align: center;
    padding-top: 18%;
    font-size: 14pt;
  } 
  .course-nav-p .hamburger a {
      color: #3a0a17;
  }

  .course-nav {
      background-color: black;
      position: fixed;
      top: 0;
      width: 100%;
      height: 52px;
      z-index: 9999;
  }

  .course-nav-p {
    background: linear-gradient(to left, white, #c282e2cc, black) ;
    background: linear-gradient( to right, #e72f6088 , #7b439791 );
    position: fixed;
    top: 0;
    width: 100%;
    height: 52px;
    z-index: 99999999999;
}

.nav-btn {
    display: inline-block;
    padding-right: 2%;
    padding-top: 2%;
    
  }

  .navbar {
    position: fixed;
    top: -1px;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    background: linear-gradient(to right, rgba(82, 82, 82, 0.973), rgba(117, 117, 117, 0.582), rgba(255, 255, 255, 0.219));
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  }
  
  .navbar-left {
    display: inline-flex;
    padding-left: 2%;
    vertical-align: middle;
    width: 28%;
  }

  .relative {
    position: relative;
  }

  .mobile-navbaar-right {
    display: none;
  }



  .mobile-navbaar-right {
    display: block;
  }

    .navbar-right {
    
        padding-right: 0%;
        display: block;
        text-align: right;
        vertical-align: middle;
        width: 100%;

        overflow-x: scroll;
        padding-left: 15px;
        padding: 5px;
        margin: 0;
        margin-top: 65px;
        
    
      }

      .navbar-right-fixed {
    
        padding-right: 5%;
        display: block;
        text-align: right;
        vertical-align: middle;
        width: 100%;

        overflow-x: scroll;
        padding-left: 0px;
        padding: 10px;
        padding-top: 13px;
        padding-bottom: 13px;
        margin: 0;
        margin-top: 0px;
        color: black;
        background: white;
        position: fixed;
        box-shadow: rgba(0, 0, 0, 0.193) 0px 0px 0px 1px;
        transition: .3s;
      }

      .all-navbar-links {
        position: relative;
        width: fit-content;
        white-space: nowrap;
        padding-left: 15px;

      }

      .scrollable-row {
        position: relative;
        width: auto;
        white-space: nowrap;
        padding-left: 15px;
      
      }

    .scrollable-row.b {
      padding-right: 7px;
      padding-left: 0px;
      position: unset;
    }

      .all-navbar-links.b {
        position: relative;
        width: fit-content;
        white-space: nowrap;
        padding-left: 0px;
        padding-right: 7px;
      }


      a.desktop-navbar-link {
        text-transform: uppercase;
        font-size: 7pt;

      }

      .right-navbar {
        display: inline-block;
        text-align: right;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 20px;
        width: 68%;
        vertical-align: middle;
      }

      .large-navbar-link {
        display: inline-block;
        position: relative;
        padding-right: 40px;
        vertical-align: middle;
        text-transform: uppercase;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 400;
        color: black;
        letter-spacing: .5px;
        font-size: 12pt;
      }

      .section {
        width: 100%;
        height: 50vh;

      }



  

      span.hamburger {
        display: block;
        position: relative;
        height: 1.5px;
        margin: 7.5px;
        border-radius: 9px;
        opacity: 1;
        width: 30px;
        transition: .4s;
    
      }
    

    
      .mobile-navbar {
        display: block;
        position: fixed;
        top: 0; left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.973);
        text-align: center;
        padding-top: 15%;
        z-index: 9;
        font-size: 10pt;
      }
    
      
    


      .mobile-navbar-right {
        display: block;
        position: fixed;
        top: 1.5%; right: 0%;
        z-index: 100;
    
      }
    
      .pad-20 {
        padding: 10px;
      }

      .nav-btn {
        display: block;
        margin: 0 auto;
        padding: 8%;
        font-size: 10pt;
    
      }


.bolder {
    font-weight: 400;
}



.hamburger.rotate-45l {
  transform: rotate(-45deg) translate(-8px, 8px); 
  transition: .4s;
  background-color: white;
  z-index: 9999999999999;
}

.hamburger.rotate-45r {
  transform: rotate(45deg) translate(3px, 2px);
  transition: .4s;
  background-color: white;
  z-index: 999999999999999;
}

.display-none {
  display: none;
}



.nav-btn-block:hover {
  cursor: pointer;
}

.nav-btn-block-inside {
  background: radial-gradient(to center, rgb(129, 129, 129), rgb(24, 24, 24), rgb(131, 131, 131));
  padding-top: 0px;
  font-weight: 800;
  font-size: 12px;
  padding: 4px;
  padding-left: 0;
  padding-right: 0;
  letter-spacing: 0.6px;
  background-clip: text;
  -webkit-background-clip: text;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}



  .full-mobile-nav {
    height: 100vh;
    width: 70%;
    background-color: white;
    position: fixed;
    top: 100px;
    right: 0;
    padding-left: 20px;
    z-index: 999999999;
    overflow-y: scroll;
  }

  .full-back-nav {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.808);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999999;
  }

  .abs-nav {
    position: fixed;
    bottom: 6vh;
    right: 20px;
    background-color: #ffffff;
    padding: 12px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 50%;
    text-align: center;
    color: black;
    z-index: 99999999999999999999999999999999999999999999999999999999999;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: .5s;
  }



  .abs-nav i {
    font-size: 16pt;
    position: absolute;
    top: 54%; left: 50%; 
    font-weight: 300;
    transform: translate(-50%, -50%);
    z-index: 999999999999999999999999999999999;
  }

  .abs-navx i {
    font-size: 16pt;
    position: absolute;
    top: 54%; left: 50%; 
    font-weight: 300;
    transform: translate(-50%, -50%);
    z-index: 999999999999999999999999999999999;
  }

  .logo-container {
    display: block;
    position: fixed;
    top: 8px;
    left: 17px;
    width: 30%;
    height: 60px;

    z-index: 999999;

  }

  img.logo {
    display: block;
    position: absolute;
    top: 0%; left: unset;
    transform: translate(unset);
    height: 62px;
    width: auto;
  
}

img.logo.blk {
  display: block;
  position: absolute;
  top: 6%; left: 50%;
  transform: translate(-50%);
  height: 48px;
  width: auto;

}

.nav-btn-wrapper {
  display: block;
  position: relative;
  text-align: right;
  padding-top: 10px;
  padding-right: 10px;
  background: linear-gradient(196deg, rgba(0, 0, 0, 0.514), transparent, transparent);
  background: none;
}

.navbar-underlay {
  position: absolute;
  background: linear-gradient(to top right, rgba(0, 0, 0, 0.767), rgba(0, 0, 0, 0.856));

  z-index: 1;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.nav-btn-wrapper-inside {

}

.nav-btn-block {


}

.nav-btn-block:hover {
  cursor: pointer;
}

.nav-btn-block-inside {
  background: radial-gradient(to center, rgb(129, 129, 129), rgb(24, 24, 24), rgb(131, 131, 131));
  padding-top: 0px;
  font-weight: 500;
  padding: 4px;
  padding-left: 0;
  padding-right: 0;
  letter-spacing: 0px;
  background-clip: text;
  -webkit-background-clip: text;
  color: white;
}

.nav-btn-block-inside.highlight {
  color: red;
}

.bottom-nav {
  position: relative;
  bottom: 0;
  background-color: #ececec;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 11.5pt;
  padding: 20px;
  padding-bottom: 100px;
}

img.bottom-nav-img {
  width: 80%;
}

.bottom-nav-buttons {
  text-align: left;


}

.bnbt {
  font-size: 11pt;
  padding: 10px;
  padding-top: 30px;
  font-weight: 600;
  color: black;
}

.bnb {
  display: block;
  line-height: 1.6em;
  padding-left: 10px;
  font-size: 9pt;
  transition: .5s;

}


.bnba {
  display: block;
  line-height: 1.6em;
  font-family: "Sanz-Bold", sans-serif;
  font-size: 9pt;
  color: black;

}


.social-links {
  padding-top: 20px;
}

.social-links i {
  font-size: 22pt;
  padding: 20px;
  padding-bottom: 0;
}

span.arrow {
  font-size: 16pt;
  padding-right: 7px;
}

a {
  text-decoration: none;
}



button.bnb {
  color: rgb(34, 30, 34);
  display: block;
  position: relative;
  padding-top: 3px;
  padding-bottom: 7px;
  text-align: left;
  transition: .5s;
}

button.bnbt {
  display: block;
}

span.assist {
  color: rgba(97, 97, 97, 0.678);

}
  
.spacers {
  padding: 15px;
  padding-left: 10px;

}

.spacers2 {
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;


}



.social-links a {
  color: rgba(0, 0, 0, 0.904);
}

.course-title-in-list {
  position: relative;
  top: 10px;
}

.course-list-item {
  margin-top: 0px;
  padding-top: 0;
  height: 260px;
  position: relative;

  width: 100%;
  overflow: hidden;
  background: white;

 
}

img.mini {
  width: 32%;
  height: auto;
  position: absolute;
  margin-top: -2%;
  display: block;
  right: 6%;
  transform: rotate(-90deg);
  padding: 0;
  vertical-align: top;
}

.bottom-40 {
  padding-bottom: 40px;
}

.div-spacer {
  padding-top: 40px;
  border-top: .5px solid rgba(0, 0, 0, 0.185);
  background: linear-gradient(5deg, rgba(64, 36, 75, 0.841), rgba(255, 255, 255, 0.928), rgba(255, 255, 255, 0.698));
  background-size: 300% 300%;

  -webkit-animation: WelcomePageGradient 5s ease infinite;
  -moz-animation: WelcomePageGradient 5s ease infinite;
  animation: WelcomePageGradient 5s ease infinite;
}

.learn-btn {
  color: #575757;
  font-size: 8pt;
  position: absolute;
  bottom: 15px;
  left: 10px;
}

.learn-btn span.arrow {
  font-size: 10pt;
}

iframe.map {
  width: 100%;
  text-align: center;
  border: 0;
  margin: 0 auto;
  margin-bottom: -6px;
  height: 400px;
}

.lowercase {
  text-transform: lowercase;
}

.abs-navx {
  position: fixed;
  top: 100px;
  right: 75%;
  background-color: transparent;

  border-radius: 50%;
  text-align: center;
  color: white;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999;
}

img.artist {
width: 100%;

margin: 0 auto;


}

img.artist.o {
  margin: unset;
  text-align: right;

}

img.artist.s {
  text-align: left;

}

.artist-box {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 94%;
  
  margin: 0 auto;
  text-align: left;
  font-size: 14px;
  line-height: 1.4em;
  color: rgba(0, 0, 0, 0.788);
  text-transform: none;
}

.abs-title {
  position: absolute;
  margin-top: 40px;
  float: right;
}

.abs-title-r{
  position: absolute;
  right: 10px;
  margin-top: 40px;
}

.zindex {
  position: absolute;
  z-index: 99999999;
}


}


.navbar-button-box-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.navbar-button-box-shadow2 {
  padding-top: 15px;
  margin-bottom: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 10px;
  border: .5px solid rgba(0, 0, 0, 0.091);
}


.blk {
  color: black;
}


@media (max-width: 380px) {
  .bottom-navbar-btn {
    color: white;
    text-transform: uppercase;
    padding: 11px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 25px;
    margin: 7px;
    font-size: 10pt;
    font-family: "Sanz-Bold", sans-serif;
    font-family: poppins, sans-serif;
    font-weight: 700;
    margin: 10px auto;
    margin-right: 5px;
    margin-left: 5px;
    vertical-align: bottom;
    display: inline-block;
    transition: .3s;
  }
}

.navbar-appears {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(210, 210, 210, 0.367);
  top: 50%; left: 50%; transform: translate(-50%, -50%);
  transition: .7s;
  animation: fade-in;
  z-index: 999999999999999999;
}

.inside-navbar-appears {
  position: inherit;
  width: 90vw;
  height: 90vh;
  background-color: white;
  top: 50%; left: 50%; transform: translate(-50%, -50%);
  border-radius: 25px;
  text-align: center;
  overflow-y: scroll;
  transition: 1s;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.inside-navbar-appears2 {
  position: inherit;
  width: 90vw;
  background-color: white;
  top: 50%; left: 50%; transform: translate(-50%, -50%);
  border-radius: 25px;
  text-align: center;
}

.small-menu-btn {
  font-size: 8pt;
  font-family: "Sanz-Bold", sans-serif;
  font-weight: 500;
  color:rgb(49, 44, 49);
  margin-bottom: 10px;
  margin-top: -4px;
}

.small-menu-btn2 {
  font-size: 8pt;
  font-family: "Sanz-Bold", sans-serif;
  font-weight: 500;
  color:rgb(49, 44, 49);
  margin-bottom: -10px;
  margin-top: -4px;
}

.platinum {
  background: linear-gradient(to left, #2E2F2F, #2E2F2F, #454747, #454747, #E7EBEC, #454747, #2E2F2F, #181919);

  background-size: 300% 300%;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  font-weight: 600;

 
  -webkit-animation: WelcomePageGradient 10s ease infinite;
  -moz-animation: WelcomePageGradient 10s ease infinite;
  animation: WelcomePageGradient 10s ease infinite;
}

.platinum-border {
  border: 1px solid #4e4e4e;
}



.platinum2 {
  background: linear-gradient(to left, #2E2F2F, #2E2F2F, #454747, #454747, #E7EBEC, #454747, #2E2F2F, #181919);

  background-size: 300% 300%;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  font-weight: 600;

  border: 1px solid #cccfcf;

}

.gold {
  background: linear-gradient(to left, #77571d, #77571d, #ffb338, #ffb338, #E7EBEC, #ffb338, #77571d, #3e2904);

  background-size: 300% 300%;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  font-weight: 600;

  border: 1px solid #77571d;
  -webkit-animation: WelcomePageGradient 10s ease infinite;
  -moz-animation: WelcomePageGradient 10s ease infinite;
  animation: WelcomePageGradient 10s ease infinite;
}


img.navbar-aam {
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 50px;
  height: 50px;
}

img.page-aam {
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 80px;
  height: 80px;
}

.no-outline {
  outline: none;
  border: none;
  font-size: 20px;
  box-shadow: none;
  padding: 10px;
  margin: 0;
}

img.navbar-aam.l {
  text-align: left;
  margin: unset;
}

.width90 {

}




* {
  -webkit-font-smoothing: antialiased;
}

.menu-name.blk-menu-btn {
  color: black;
 }


