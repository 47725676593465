 @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes move {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    50% {
      -webkit-transform: translateX(-1500px);
      transform: translateX(-1500px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }

  .slide-in-top {
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  

 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

a {
  color: #772dac;
}

.scale-up-center {
	-webkit-animation: scale-up-center 30.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 30.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


  @keyframes slide-back-forth {
    0% {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      opacity: 1;
    }
    50% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      opacity: 1;
    }
  }


@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


.slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes scrolling-left1 {
  0% {transform: translateX(100%);
      -webkit-transform: translateX(100%);}
    100% {transform: translateX(-100%);
        -webkit-transform: translateX(-100%);}
}


  @font-face {
    font-family: "Sanz-Reg";
    src: local("Sanzettica-RegCond"), 
    url("../fonts/Sanzettica-RegCond.ttf") format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-Thin";
    src: local("Sanzettica-ThinCond"), 
    url("../fonts/Sanzettica-ThinCond.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-Thin-Reg";
    src: local("Sanzettica-Thin"), 
    url("../fonts/Sanzettica-Thin.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-Thin-Italic";
    src: local("Sanzettica-ThinItalic"), 
    url("../fonts/Sanzettica-ThinItalic.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-Bold";
    src: local("Sanzettica-Bold"), 
    url("../fonts/Sanzettica-BoldCond.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-Heavy";
    src: local("Sanzettica-Heavy"), 
    url("../fonts/Sanzettica-HeavyCond.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-Black";
    src: local("Sanzettica-Black"), 
    url("../fonts/Sanzettica-BlackCond.ttf") format("truetype");
   }
  

.italic {
  font-family: "Sanz-Thin-Italic";
}

.italic.smaller {
  font-size: 9pt;
  font-weight: 400;
  color:rgb(79, 40, 87);
  font-family: poppins, sans-serif;
}

.italic.smaller.wht {
  color: white;
}

.slide-in-right {
	-webkit-animation: slide-in-right .5s cubic-bezier(0.445, 0.050, 0.550, 0.950) both;
	        animation: slide-in-right .5s cubic-bezier(0.445, 0.050, 0.550, 0.950) both;
}

.moving-grad-4 {
  background: linear-gradient(80deg,  transparent, transparent, transparent, transparent, transparent, rgba(255, 255, 255, 0.241), transparent, transparent, transparent);
  -webkit-animation: slide-back-forth 10s  infinite;
  -moz-animation: slide-back-forth  10s  infinite;
  animation: slide-back-forth 10s infinite;
  width: 150%;
  left: 25%;
  height: 34px;
  margin-top: -7px;
  position: absolute;
}

.moving-grad-5 {
  background: linear-gradient(80deg,  transparent, transparent, transparent, transparent, transparent, rgba(255, 255, 255, 0.241), transparent, transparent, transparent);
  -webkit-animation: slide-back-forth 10s  infinite;
  -moz-animation: slide-back-forth  10s  infinite;
  animation: slide-back-forth 10s infinite;
  width: 280%;
  left: -55%;
  height: 3px;
  margin-top: 5px;
  position: absolute;

}

.moving-gradient {
  background: linear-gradient(to left,#772dac, #f43ea8);

  background-size: 300% 300%;

  -webkit-animation: WelcomePageGradient 8s ease infinite;
  -moz-animation: WelcomePageGradient 8s ease infinite;
  animation: WelcomePageGradient 8s ease infinite;
}

.slide-back-forth {
  -webkit-animation: slide-back-forth 10s  infinite;
  -moz-animation: slide-back-forth  10s  infinite;
  animation: slide-back-forth 10s  infinite;
}

.moving-gradient-2 {
  background: linear-gradient(to bottom right, #e72f60, #7b4397);
  background-size: 300% 300%;

  -webkit-animation: WelcomePageGradient 4s ease infinite;
  -moz-animation: WelcomePageGradient 4s ease infinite;
  animation: WelcomePageGradient 4s ease infinite;
}

@-webkit-keyframes WelcomePageGradient {
  0%{background-position:0% 47%}
  50%{background-position:100% 54%}
  100%{background-position:0% 47%}
}
@-moz-keyframes WelcomePageGradient {
  0%{background-position:0% 47%}
  50%{background-position:100% 54%}
  100%{background-position:0% 47%}
}
@keyframes WelcomePageGradient {
  0%{background-position:0% 47%}
  50%{background-position:100% 54%}
  100%{background-position:0% 47%}
}

@-webkit-keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      opacity: 1;
    }
  }
  

.scale-in-ver-center {
	-webkit-animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.fade-in {
	-webkit-animation: fade-in .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}




.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
padding-top: 40px;
        }


* {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.desktop-sxn {
  position: relative;
  display: inline-block;
  width: 40%;
  height: fit-content;
}

.desktop-page {
  position: relative;
  margin-top: 0;
  min-height: unset;
  width: 100%;
  min-width: 1000px;
  overflow: hidden;
}

* {
margin: 0;
padding: 0;
-webkit-font-smoothing: antialiased;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

video.main-vid {
display: block;
width: 105%;
position: relative;
top: 0px;
left: 0%;
height: 100%;
opacity: 1;
}

video.main-vid.third {
margin-top: 40px;
height: 100%;
left: unset;
opacity: 1;
}

video.main-vid.banner-vid {
position: absolute;
display: block;
top: 50%; left: 50%; transform: translate(-50%, -50%);
}

.banner {
display: block;
padding: 20px;
margin: 20px;
}

.banner-overlay {
display: block;
height: 100%;
width: 100%;
position: absolute;
z-index: 100;
top: 0;left: 0;
background: linear-gradient(to bottom, rgba(0, 0, 0, 0.068), rgba(0, 0, 0, 0.418));

}

.video-name {
position: relative;
z-index: 200;
}

img.faq-img {
  margin-top: 60px;
}

img.video-name-image.a {
width: 75%;
position: absolute;
text-align: center;
top: 4px;
right: 30px;
}

img.video-name-image.b{
width: 75%;
position: absolute;
text-align: center;
top: 34px;
right: 0px;
}

img.video-name-image.c{
width: 75%;
position: absolute;
text-align: center;
top: 4px;
right: 0px;
}

.acad-top-section {
display: inline;
position: relative;
width: 50%;
}



img.acad-front-2 {
position: relative;
width: 36%;
background: transparent;
height: auto;
margin: 0 auto;
margin-top: 2%;
text-align: center;
z-index: 2;
display: none;
}

.acad-front-container {
position: relative;
overflow: hidden;
width: 100%;
z-index: 10000;
display: block;
}

button.intro-button-a {
  float: left;
  padding-left: 15px;
}

button.intro-button-b {
  float: left;
  padding-left: 15px;
}

button.bnb {
  color: black;
  font-family: "Sanz-Bold", sans-serif;
  font-weight: 300;
}

img {
width: 100%;
}

.page {

display: block;
margin-top: 100px;
position: relative;
}

.section {
display: block;
position: relative;
margin: 0;
height: fit-content;
padding: 0;
width: 100%;
white-space: normal;
}

.section3 {
position: relative;
z-index: 100;
padding-top: 90px;
padding-bottom: 30px;
}

.entry-text-low {
color: rgba(51, 48, 51, 0.836);
font-weight: 200;
font-size: 18pt;
text-align: center;
letter-spacing: 0.5px;


}

img.acad-front {
position: relative;
z-index: 100;
width: 76%;
left: 0%;
top: 0px;
background: transparent;
display: none;
}

img.acad-front-desktop {
position: relative;
z-index: 1000;
width: 125%;
left: 50%;
margin-top: -7%;

padding-bottom: -1%;
background: transparent;
transform: translate(-50%);
display: block;
}
.black-friday-special {
font-size: 42pt;
font-weight: 200;


background: linear-gradient(to left, rgba(3, 3, 3, 0.26), #7b4397, #7b4397, #f43ea8, #f43ea8, #f43ea8);

background-size: 200% 200%;
-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
padding-top: 10px;
padding-bottom: 5px; 
letter-spacing: 0;
background-clip: text;

-webkit-background-clip: text;
-webkit-font-smoothing: antialiased;
color: transparent;
}

.l-r-20 {
  position: relative;
  width: 50%;
  display: inline-block;
  background: black;
  margin-top: 0px;
  padding-top: 0px;
  height: 100vh;
  vertical-align: top;
}

.black-friday-special-2 {
font-size: 32pt;
font-weight: 200;
background: linear-gradient(to right, rgb(79, 40, 87),  rgb(241, 42, 118));
padding-top: 7px;
padding-bottom: 5px; 
letter-spacing: -0.5px;
background-clip: text;
-webkit-background-clip: text;
-webkit-font-smoothing: antialiased;
color: transparent;

}

.j {
text-align: justify;
}

.uppercase {
text-transform: uppercase;

}

.upcoming-dates {
background: black;
color: white;
}


.slogan {
font-size: 18pt;
font-weight: 300;
font-style: italic;
padding-bottom: 20px;
margin-top: -20px;
}

.action-button {
display: block;
position: relative;
z-index: 900000;
background-color: transparent;
width: 140px;
height: 46px;
margin: 0px auto;
margin-top: -20px;
vertical-align: middle;
font-size: 40px;
color: white;
padding: 0px;
text-align: center;
text-transform: uppercase;
font-size: 13px;
-webkit-appearance: none;
transition: .4s;
border-image: linear-gradient(to right, #005e7f, #61b6cd);
border-radius: 50px;
background-size: 100%;
background: rgb(0, 0, 0);
border: 2px transparent;
border-radius: 50px;
background-origin: border-box;
background-clip: content-box, border-box;
vertical-align: middle;
}

.action-button::after {
z-index: -100000;
border-radius: 50px;
content: '';
display: block;    
position: absolute;
top: -1px;
left: -1px;
width: calc(100% + 2.5px);
height: calc(100% + 2px);
transform: translateZ(-1px);
background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
background-size: 400% 400%;

-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
}



.action-button-inside {
display: block;
position: absolute;
z-index: 1000000;
background-color: #ffffff;
width: 140px;
height: 46px;
border-radius: 50px;
padding: 20px;

}


.action-button-text {
display: block;
position: absolute;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 11pt;
background-color: #ffffff;

padding-top: 14px;
padding-bottom: 13px;
vertical-align: middle;
top: 49%;
transform: translate(0, -50%);
text-align: center;
width: 99%;
color: rgb(0, 0, 0);
border-radius: 50px;
}

.more-btn {
color: #7b4397;

padding-top: 0px;
padding-bottom: 0px;
padding: 5px;
margin: 0 auto;
letter-spacing: -0.5px;
width: 150px;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 10pt;
text-transform: uppercase;
font-weight: 300;

border-radius: 25px;
padding-left: 10px;
padding-right: 10px;
text-align: center;
background-color: transparent;
width: fit-content;
font-weight: 600;
background: linear-gradient(to right, rgba(79, 40, 87, 0.973),  rgb(241, 42, 118));
padding-top: 0px;
letter-spacing: -0.5px;
color: rgb(255, 255, 255);
margin-top: 10px;
padding-bottom: 40px;



background:
-moz-linear-gradient(
-72deg,
#dedede,
#ffffff 16%,
#dedede 21%,
#ffffff 24%,
#521f66 27%,
#dedede 36%,
#ffffff 45%,
#ffffff 60%,
#dedede 72%,
#ffffff 80%,
#dedede 84%,
#a78eac
);
background:
-webkit-linear-gradient(
-72deg,
#dedede,
#ffffff 16%,
#dedede 21%,
#ffffff 24%,
#521f66 27%,
#dedede 36%,
#ffffff 45%,
#ffffff 60%,
#dedede 72%,
#ffffff 80%,
#dedede 84%,
#a78eac
);
background:
-o-linear-gradient(
-72deg,
#dccbdd,
#ffffff 16%,
#dedede 21%,
#ffffff 24%,
#521f66 27%,
#dedede 36%,
#ffffff 45%,
#ffffff 60%,
#dedede 72%,
#ffffff 80%,
#dedede 84%,
#a78eac
);
background:
linear-gradient(
-72deg,
#dedede,
#ffffff 16%,
#dedede 21%,
#ffffff 24%,
#521f66 27%,
#dedede 36%,
#ffffff 45%,
#ffffff 60%,
#dedede 72%,
#ffffff 80%,
#dedede 84%,
#a78eac
);
background-clip: text;
-webkit-background-clip: text;
}

.space-box {
padding-top: 0px;
padding-bottom: 20px;
font-weight: 300;
}

.small {
color: rgba(0, 0, 0, 0.5);


letter-spacing: 0;
background-clip: text;
background-size: 100% 100%;
-webkit-background-clip: text;
-webkit-font-smoothing: antialiased;
color: #ffffffbd;
font-size: 10pt;
font-weight: 300;
background: transparent;
}

.smaller {
font-size: 12pt;
font-weight: 300;
line-height: 1.6em;
letter-spacing: 0.8;
}

.c {
text-align: center;
margin: 0 auto;
}

.white-back-gradient-t {
margin-top: -50px;
padding-top: 50px;
width: 100%;
z-index: 99;
height: 0px;
position: absolute;
background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.397), rgba(255, 255, 255, 0.699), rgba(255, 255, 255, 0.897), white);
}

.white-back-gradient-b {
margin-top: -22px;
padding-bottom: 0px;
width: 100%;
z-index: 99;
height: 5px;
position: absolute;
background: linear-gradient(to top, transparent, rgba(255, 255, 255, 0.397), rgba(255, 255, 255, 0.699), rgba(255, 255, 255, 0.897), white);
}

.hidden {
display: none;
}

.enroll-now {
position: absolute;
top: 20px; right: 20px;
color: white;
font-weight: 500;
padding-top: 9px; padding-bottom: 9px;
padding-left: 13px; padding-right: 13px;

background: linear-gradient(to left, black, #7b4397, black, #e72f60);

background-size: 300% 300%;

-webkit-animation: WelcomePageGradient 10s ease infinite;
-moz-animation: WelcomePageGradient 10s ease infinite;
animation: WelcomePageGradient 10s ease infinite;
z-index: 0;
border-radius: 20px;
}

.course-wrap {
width: 27%;
vertical-align: top;
height: auto;
padding: 0;
margin: 2%;
position: relative;
display: inline-block;
font-weight: 200;
font-size: 9pt;
text-align: center;

margin-bottom: 2%;
}

.course-wrap-inside {
width: 100%;
margin: 0 auto;
padding-top: 0%;
padding-bottom:0%;
position: relative;
display: block;


}


img.course {
position: relative;
width: 100%;
border-top-left-radius: 12px;
border-top-right-radius: 12px;
margin-bottom: 5px;
}

.gray-wrap {
background: linear-gradient(to bottom, black, black );
background: white;
padding: 20px;
padding-left: 0;
padding-right: 0;
font-size: 10pt;
font-weight: 500;
position: relative;
top: 0; text-align: left;
z-index: 0;
width: 100%;
color: rgba(0, 0, 0, 0.815);
text-transform: uppercase;

}

.gray-wrap.list {
background: white;
color: rgba(0, 0, 0, 0.507);
}

.gray-wrap .upcoming {
font-size: 8pt;
font-weight: 300;
padding-left: 10px;
color: #000000a6;
text-transform: uppercase;

}

.slogan2 {
color: #ffffffa6;
font-weight: 300;
padding-top: 10px;
}

.gray-wrap .month {
font-size: 14pt;
padding-left: 10px;
text-transform: uppercase;
font-weight: 400;
color: white;
color: black;
}

.year {
padding-left: 10px;
margin-top: -1px;

}

.gray-back {
background-color: #f9f9f9;
position: relative;
margin-top: 30px;
padding-top: 40px;
display: inline-block;
width: 100%;
vertical-align: top;
}


.purp-back {
background: black;
padding-top: 0px;
z-index: 999999999;
}

.grad-purp-back {
background: linear-gradient(to right, #521f6665 , transparent, transparent);
position: absolute;
opacity: 1;
width: 100%;
height: 100%;

padding-bottom: 40px;
-webkit-animation: slide-back-forth 8s  infinite;
-moz-animation: slide-back-forth  8s  infinite;
animation: slide-back-forth 8s infinite;
}

.blk-back {
background: rgb(14, 14, 14);
}

.review-row {
white-space: nowrap;
overflow: hidden;
overflow-x: scroll;
}

.review-container {
width: 6%;
background: white;
display: inline-block;
height: fit-content;
white-space: normal;
vertical-align: top;
text-align: center;
margin: 5px;
border-radius: 12px;
box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

i {
color: inherit;
}

*::-webkit-scrollbar {
display: none;
}

.review-inside {
width: 90%;
text-align: left;
margin: 0 auto;
padding: 15px;
color: rgb(70, 70, 70);

}

.footer {
font-size: 7.5pt;
color: rgb(70, 70, 70);
padding: 10px;
}

.drop {
padding-top: 12px;
padding-bottom: 12px;
border-bottom: 1px solid rgba(201, 201, 201, 0.623);
animation: fade-in 2s;
}

span.add {
position: absolute;
right: 20px;
}

span.plus {
position: absolute;
right: 20px;
background: linear-gradient(to left, black, #7b4397, black, #e72f60);
background-size: 300% 300%;

-webkit-animation: WelcomePageGradient 10s ease infinite;
-moz-animation: WelcomePageGradient 10s ease infinite;
animation: WelcomePageGradient 10s ease infinite;
color: transparent;
background-clip: text;
text-transform: uppercase;
font-size: 8pt;
font-weight: 400;
}


.mobile-video-container {
position: fixed;

margin-top: 20px;
width: 100%;
opacity: .1;
z-index: 1;

}

.vid-container {
position: absolute;
top: 0px;
left: 0;
width: 100%;
height: 100vh; overflow: hidden;
}

video.mobile-vid.a {
position: absolute;
top: 90px;
opacity: .45;
width: 100%;
}




.light {
font-size: 7.5pt;
color: rgba(70, 70, 70, 0.76);
animation: fade-in 2s;
}

.entry {
background: linear-gradient(to right, rgba(85, 85, 85, 0.973), rgba(95, 95, 95, 0.795));
background-clip: text;
-webkit-background-clip: text;
color: transparent;
margin-top: 10px;
font-size: 14px;
}

.course-detail-page {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100vh;
overflow: hidden;
overflow-y: scroll;
background-color: white;
z-index: 9999;
}

.pad-top-90 {
padding-top: -70px;
}

.padding-bottom-20 {
  margin-top: 0vh;
  padding-bottom:20px;
  background: transparent;
  color: black;
  width: 100%;
  margin-bottom: 0px;
}

.desktop-override {
  background: black;
}

.spacebar {
height: 4px;
}

.course-page-inside {
height: fit-content;
padding-bottom: 20vh;
width: 100%;
}

img.full-op {
opacity: 1;
padding-top: .8em;
}

.full-btn {
position: fixed;
z-index: 9999999;
top: 90%;
left: 50%;
width: 40%;

transform: translate(-50%, -95%);
}

.question-wrap {
border-bottom: 1px solid rgb(213, 213, 213);
width: 96%;
margin: 0 auto;
font-size: 14px;
}

.faq-page {
padding-top: 65px;

}

.class-page {
padding-top: 65px;
text-transform: uppercase;
background-color: #f7f7f7;
}

span.right-angle {
position: absolute;
right: 20px;
}

.nav-link-btn-a {
font-size: 14pt;
font-weight: 200;
color: white;
letter-spacing: 0.5px;
animation: slide-in-right .8s;
}

.nav-link-btn-b {
font-size: 14pt;
font-weight: 200;
color: white;
letter-spacing: 0.5px;
animation: slide-in-right 1s;
}

.nav-link-btn-c {
font-size: 14pt;
font-weight: 200;
color: white;
letter-spacing: 0.5px;
animation: slide-in-right 1.2s;
}

.rotate-45l {
transform: rotate(-45deg) translate(-8px, 8px); 
transition: .4s;
background-color: white;
}

.rotate-45r {
transform: rotate(45deg) translate(3px, 2px);
transition: .4s;
background-color: white;
}

.class-wrap-2 {
text-transform: uppercase;
margin: 20px;
background-color: white;
padding-bottom: 20px;
text-align: center;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.course-order-header {
background: white;
color: #4d4d4d;
font-size: 18pt;
padding-top: 10px;
padding-bottom: 10px;
}

.body-page {
width: 700px;
margin: 0 auto;
}

.body-page-700 {
width: 700px;
width: 0 auto;
}



.half {
position: relative;
display: inline-block;
width: 50%;
overflow: hidden;
vertical-align: middle;
padding-top: 0;
text-align: center;

}

.right {
z-index: 99;
background: linear-gradient(to right, #e72f60, white);
margin-left: -59px;
}
.vert-top {
vertical-align: top;
}


.details {
background: white;
color: black;
text-align: left;
padding-top: 10px;
padding-left: 10px;
padding-right: 20px;
padding-bottom: 20px;

}

.class-cost {
padding-top: 5px;
padding-bottom: 10px;
line-height: 1.5em;
font-family: "Sanz-Reg", sans-serif;
font-size: 10pt;
}

.class-detail-title {
text-transform: uppercase;
font-size: 9.5pt;
font-weight: 400;
font-family: "Sanz-Bold", sans-serif;
}
.desktop-pad {
padding: 40px; 
}

.mobile-no {
display: block;
}

.mobile-yes {
display: none;
}

.closed-container {
padding: 12px;
padding-top: 18px;
padding-bottom: 18px;
border-bottom: 1px solid rgba(214, 214, 214, 0.418);
background: linear-gradient(to left, black, #7b4397, black, #e72f60);
background-size: 300% 300%;

-webkit-animation: WelcomePageGradient 10s ease infinite;
-moz-animation: WelcomePageGradient 10s ease infinite;
animation: WelcomePageGradient 10s ease infinite;
color: transparent;

text-transform: uppercase;
color: rgba(0, 0, 0, 0.918);
font-size: 11pt;
background: rgba(255, 255, 255, 0.966);
font-weight: 400;
font-family: "Sanz-Reg", sans-serif;
}

.closed-container:hover {
cursor: pointer;
}

/* additions */


.review-container {
width: 16%;
background: white;
display: inline-block;
height: fit-content;
white-space: normal;
vertical-align: top;
text-align: center;
margin: 5px;
border-radius: 12px;
box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}




.purp-back {


}

.entry-text-low {
color: rgba(51, 48, 51, 0.836);
font-weight: 200;
font-size: 11pt;
text-align: center;
letter-spacing: 0.5px;


}

.page {
text-align: center;
}

.desktop-pad {
padding: 0; 
}

.full-btn {
position: fixed;
z-index: 9999999;
top: 90%;
left: 50%;
width: 90%;
transform: translate(-50%, -95%);
}

.section4 {
text-transform: uppercase;
font-size: 9pt;
}

.body-page {
width: 100%;
padding-top: 60px;
text-transform: uppercase;
}

.half {
width: 100%;
position: relative;
display: block;
}




.video-name {
position: absolute;
z-index: 1000;
}


img.video-name-image {
width: 75%;
height: auto;
position: absolute;
text-align: center;
top: 36px;
right: 0px;
z-index: 300;
display: block;
}



.remaining-title.a{
color: white;
position: absolute;
top: 50px;
right: 60px;
z-index: 300;
font-size: 10.5pt;
font-weight: 300;
text-transform: uppercase;
color: white;
font-weight: 300;
}

.mobile-banner {
position: relative;
padding-top: 0px;
display: inline-block;
height: fit-content;
min-height: 300px;
margin-bottom: 0px;
margin-top: -10px;
vertical-align: top;
width: 100%;
background: linear-gradient(to bottom, white, #592772);

}

.mobile-banner-overlay {

background: linear-gradient(to bottom, transparent, black);
position: absolute;
top: 100px;
width: 100%;
height: 100%;
display: none;
z-index: 0;
}


.banner-overlay {
display: none;
height: 100%;
width: 100%;
position: absolute;
z-index: 100;
top: 0;left: 0;
background: linear-gradient(to bottom, rgba(0, 0, 0, 0.103), rgba(0, 0, 0, 0.418));

}
video.main-vid {
display: block;

position: relative;
top: 0px;
left: 0;

opacity: 1;
transform: rotate(90deg);
}




img {
height: 100%;
}


.half {
position: relative;
display: block;
width: 100%;
overflow: hidden;
vertical-align: middle;
height: 65vh;
}

.padding-20 {
padding-top: 80px;
padding-bottom: 80px;
}

.right-navbar {
display: none;
}

.mobile-no {
display: none;
}

.mobile-yes {
display: block;
}

.section {
white-space: nowrap;
width: 100%;
overflow: hidden;
margin-top: -5px;
}

.mobile-third {
position: relative;
display: inline-block;
width: 50%;
white-space: nowrap;
vertical-align: middle;
overflow: hidden;
margin-bottom: -5px;
}



video.mobile-vid {
position: relative;

}

video.mobile-vid-half {


}

.block {
width: 100%;
margin: 0 auto;
min-height: 80vh;
margin-bottom: -70px;
height: fit-content;
position: relative;
background: transparent;
overflow: hidden;
vertical-align: middle;
text-align: left;
width: 50%;
display: inline-block;
}

.block-full {
width: 100%;
margin: 0 auto;
min-height: 60vh;
height: fit-content;
padding-top: 20px;
padding-bottom: 20px;
position: relative;
background: linear-gradient(white, transparent);;
vertical-align: middle;
text-align: left;
display: block;

}

.block-inside {
width: 100%;
margin-top: 0%;
padding-top: 0;
height: 100%;
}

.moving-gradient-2 {
background: linear-gradient(to bottom right, #e72f60, #7b4397);
background-size: 300% 300%;

-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
}

.action-button {
display: block;
position: relative;
z-index: 900000;
background-color: transparent;
width: 130px;
height: 42px;
margin: 0px auto;
margin-top: -10px;
vertical-align: middle;
font-size: 40px;
color: white;
padding: 0px;
text-align: center;
text-transform: uppercase;
font-size: 13px;
-webkit-appearance: none;
transition: .4s;
border-image: linear-gradient(to right, #005e7f, #61b6cd);
border-radius: 50px;
background-size: 100%;
background: rgb(0, 0, 0);
border: 2px transparent;
border-radius: 50px;
background-origin: border-box;
background-clip: content-box, border-box;
vertical-align: middle;
}

.action-button-inside {
display: block;
position: absolute;
z-index: 1000000;
background-color: #ffffff;
width: 120px;
height: 42px;
border-radius: 50px;
padding: 20px;

}
.action-button-text {
display: block;
position: absolute;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 9pt;
background-color:transparent;

padding-top: 11px;
padding-bottom: 11px;
vertical-align: middle;
top: 49%;
transform: translate(0, -50%);
text-align: center;
width: 99%;
color: rgb(255, 255, 255);
border-radius: 50px;
}

.acad-front-container {
position: absolute;
overflow: hidden;
width: 100%;
background: transparent;
background-color: transparent;
top: 0; left: 50%; transform: translate(-50%, -50%);
}


.wrap {
white-space: normal;
}

video.mobile-vid-half {
width: 100%;
display: inline-block;
margin: 0;
padding: 0;

margin-bottom: -8px;
}

.inline-block {
width: 45%;
margin: 3%;
display: inline-block;
text-align: center;
}

img.acad-front-3 {
position: relative;
width: 80%;
margin: 0 auto;
padding-top: 3%;
text-align: center;
z-index: 999;
}

img.acad-front-4 {
margin-top: -34%;
width: 85%;
position: relative;
z-index: 999;
}

.desktop {
width: 100%;
margin-top: 90px;
padding-top: 10px;
text-align: center;
}

.mobile {
display: none;
}


.width-80 {
width: 83%;
margin: 30px auto;
background: white;
background: linear-gradient(to bottom left, #7b439770, transparent, transparent, rgba(0, 0, 0, 0.555));
background-size: 200% 200%;
-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
}


.width-96 {
width: 46%;
display: inline-block;
vertical-align: middle;
margin: 0 auto;
padding-left: 2%;
padding-right: 2%;
}

video.whyus-vid {
position: relative;
display: inline-block;
width: 100%;
vertical-align: middle;
}
.top-150 {
padding-top: 100px;
}

video.width-100 {
height: 80vh;
width: auto;
margin: 0 auto;
}

.sideways {
  position: relative;
  padding-top: 0px;
  padding-left: 100px;
  left: 5px;
  color: black;
  width: auto;
  display: block;
  font-family: "Sanz-Thin-Reg";
  transform-origin: 0 0;
  transform: rotate(-90deg);
  font-size: 22px;
  width: 400px;
  background: linear-gradient(to left, black, #7b4397, black, #e72f60);
  background-size: 300% 300%;
  -webkit-animation: WelcomePageGradient 4s ease infinite;
  -moz-animation: WelcomePageGradient 4s ease infinite;
  animation: WelcomePageGradient 4s ease infinite;
  background-clip: text;
  margin-top: 350px;
  -webkit-background-clip: text;
  color: transparent;
  font-family: "Sanz-Thin", sans-serif;
  letter-spacing: 9.5px;
  z-index: 999999999;


}


video.inline-block {
vertical-align: top;
padding-top: 0px;
width: 100%;
position: relative;
display: inline-block;
height: auto;
}

.courses-page {
padding-top: 60px;
min-width: 800px;
margin: 0 auto;
}

/* additions */



img.full-op {
opacity: 1;
}

.inline-left {
width: 2%;
height: 10em;
display: inline-block;
vertical-align: top;
}

.inline-right {
display: inline-block;
position: relative;
padding-top: 0;
padding-bottom: 3.25em;
width: 87%;
height: auto;
vertical-align: top;
}

h1 {
position: relative;

color: white;
font-size: 3.5vw;
font-weight: 200;
text-align: left;
padding-left: 0px;

text-transform: uppercase;
z-index: 99999;
font-family: "Sanz-Bold";

}

h1.page-title {
  position: absolute;
  text-align: center;
  white-space: normal;
  width: 100%;
  font-size: 36px;
  margin: 0; padding: 0;
  padding-top: 40px;
  height:60px;
  top: 26%;
  font-family: "Sanz-Thin", sans-serif;
  letter-spacing: -2.9px;
  transition: .7s;

}

h2 {
position: relative;
top: 79%; transform: (0, -40%);
color: white;
font-size: 1.3vw;
font-weight: 300;
text-align: left;
padding-left: 0px;
width: 100%;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
text-transform: uppercase;
z-index: 99999;
line-height: 1.45em;
letter-spacing: .3px;
}

button.intro-button {
position: absolute;
display: inline-block;
top: 91%;
left: 0; transform: (0%, -30%);
z-index: 100;
color: white;
text-transform: uppercase;
text-align: left;
padding-left: 14px;
margin: 5px;
font-weight: 400;
z-index: 99999;
margin: 0 auto;

}

button.center {
padding-left: unset;
margin: 0 auto;
text-align: center;
bottom: 9.5%;
top: unset;
left: 50%; transform: translate(-50%);
width: fit-content;
}

.inner-button {
padding: 13px;
border: 1px solid white;
color: white;
font-size: 1.3vw;
margin: 3px;
margin-top: 10px;

}

button {
background: transparent;
box-shadow: none;
border: none;
}



.vid-overlay {
position: absolute;
background: rgba(0, 0, 0, 0.212);
background: linear-gradient(to top right, rgba(255, 255, 255, 0.266), rgba(0, 0, 0, 0.22), rgba(14, 14, 14, 0.527));
background: linear-gradient(to bottom, rgba(0, 0, 0, 0.536), rgba(0, 0, 0, 0.212));
height: 102%;
width: 100%;
z-index: 0;
top: 0;
}

.vid-overlay2 {
  position: absolute;
  background: rgba(0, 0, 0, 0.212);
  background: linear-gradient(to top left, transparent, transparent, rgba(14, 14, 14, 0.365));

  height: 102%;
  width: 100%;
  z-index: 0;
  top: 0;
  }

  .vid-overlay3 {
    position: absolute;
    background: rgba(0, 0, 0, 0.196);
   
    height: 102%;
    width: 100%;
    z-index: 0;
    top: 0;
    }

    .vid-overlay4 {
      position: absolute;
      background: rgba(0, 0, 0, 0.212);
      background: linear-gradient(-10deg, rgba(0, 0, 0, 0), transparent, transparent, rgba(0, 0, 0, 0.168), transparent, transparent, transparent, transparent, transparent, transparent, rgba(14, 14, 14, 0));
      height: 100%;
      width: 140%;
      z-index: 0;
      top: -70%;
      left: -45%;
      }

.top-150 {
padding-top: 135px;
}

video.width-100 {
width: 100%;
height: auto;
}

video.full-width {
  margin-top: -15%;
  width: 120%;
}

.sideways {


}

.sxn {
position: relative;
display: block;
padding-top: 0px;
padding-bottom: 10px;
z-index: 999;
width: 100%;

}

.inline-paragraph {
display: inline-block;
position: relative;
width: 97%;
vertical-align: top;
text-transform:  none;
font-weight: 300;
font-size: 16px;
line-height: 1.4em;
opacity: 1;
top: 0;
padding-top: 2em;
letter-spacing: .18px;
}

.paragraph-title {
font-size: 18.5pt;
padding-top: 15px;
padding-bottom: 15px;
font-weight: 400;
color: #6c2391b6;
background: linear-gradient(to left, black, #7b4397, black, #e72f60);
background: linear-gradient(to left, #772dac, #f43ea8);
background-size: 300% 300%;
-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
background-clip: text;
margin-top: 10px;
-webkit-background-clip: text;
-webkit-font-smoothing: antialiased;
color: transparent;
font-family: "Sanz-Reg", sans-serif;
letter-spacing: -1px;
text-transform: uppercase;
line-height: 1.2em;
font-family: poppins, sans-serif;

}

.click-thru {
font-weight: 600;
padding-top: 20px;
color: #272727;
}

.space-title2 {
  padding-top: 50px;
  opacity: 0;
}

.space-title {
font-size: 20px;
padding-top: 30px;
animation: 4s fade-in;

}

.space-title-small {
  position: relative;
  font-size: 13pt;
  font-weight: 200;
  padding-top: 0px;
  display: block;
  letter-spacing: 0px;
  padding-bottom: 10px;
  font-family: "Sanz-Thin", sans-serif;
  text-transform: uppercase;
  color: #f5f5f5;
  }


video.inline-block {

padding-top: unset;
display: block;
width: 100%;
vertical-align: left;
overflow: hidden;
height: unset;
left: unset;
left: 0;
}
.class-sample-row {
width: 100%;
margin: 0 auto;

display: block;
}



.class-sample {
width: 300px;
margin: 0%;

vertical-align: top;
display: inline-block;
transition: .5s;

}


.class-sample img {
position: relative;
width: 96%;
padding: 2%;
margin: 0 auto;
top: 0%; transform: translate(0, 0%);
vertical-align: top;
height: auto;
margin: 0px;
margin-bottom: 0;
}

.course-program-title {
text-transform: uppercase;
font-size: 20pt;
font-weight: 200;
padding-left: 10px;
color: rgb(0, 0, 0);
background: linear-gradient(to left, rgba(3, 3, 3, 0.26), #7b4397, #7b4397, rgb(0, 0, 0), #e72f60, #e72f609c);
background: linear-gradient(to left, black, #7b4397, black, #e72f60);
background-size: 100% 100%;
-webkit-animation: WelcomePageGradient 10s ease infinite;
-moz-animation: WelcomePageGradient 10s ease infinite;
animation: WelcomePageGradient 10s ease infinite;
background-clip: text;
padding-top: 2px;
-webkit-background-clip: text;
-webkit-font-smoothing: antialiased;
color: rgba(71, 71, 71, 0.384);
font-family: "Sanz-Thin", sans-serif;
line-height: 1.2em;
}

.course-program-title2 {
  text-transform: uppercase;
  font-size: 12pt;
  font-weight: 600;
  padding-left: 0px;
  color: rgb(0, 0, 0);
  background: linear-gradient(to left, rgba(3, 3, 3, 0.26), #7b4397, #7b4397, rgb(0, 0, 0), #e72f60, #e72f609c);
  background: linear-gradient(to left, black, #7b4397, black, #e72f60);
  background-size: 200% 200%;
  -webkit-animation: WelcomePageGradient 6s ease infinite;
  -moz-animation: WelcomePageGradient 6s ease infinite;
  animation: WelcomePageGradient 6s ease infinite;
  background-clip: text;
  padding-top: 2px;
  -webkit-background-clip: text;
  -webkit-font-smoothing: antialiased;
  color: rgba(71, 71, 71, 0.384);
  font-family: poppins, sans-serif;
  line-height: 1.2em;
  }



.course-program-title.b {
position: absolute;
top: 10%;
font-size: 22pt;
font-weight: 300;
transform: translate(0, -50%);
margin-top: 30px;
width: 55%;

}


.course-program-subtitle {
text-transform: uppercase;
padding-left: 10px;
font-weight: 600;
font-size: 10pt;
color: rgba(36, 36, 36, 0.768);
font-family: poppins, sans-serif;
}

.course-program-subtitle.b {
position: absolute;
top: 50%;
transform: translate(0, -50%);
width: 55%;
}
.course-program-subtitle.e {
  position: absolute;
  bottom: unset;
  top: 29%;
  width: 55%;
  line-height: 1.6em;
}

.course-program-subtitle.f {
  position: absolute;
  bottom: unset;
  top: 37%;
  width: 55%;

}


.course-program-subtitle.c {
position: relative;

transform: translate(0, -50%);
}





.class-sample-2 {
width: 100%;
height: auto;
display: block;
}

.class-sample-2 img {
position: relative;
width: 96%;
margin: 0 auto;
height: auto;
margin: -2px;
margin-bottom: -10px;
}

.pad-top-90 {
padding-top: 10px;
}

.inline-block {
width: 100%;
display: block;
text-align: center;
padding-bottom: 20px;
}
.mobile {
display: block;
padding-top: 10px;
padding-bottom: 10px;
}

.enroll-now {
position: absolute;
top: 50%; right: 20px;
transform: translate(0, -50%);
color: white;
font-weight: 600;
padding-top: 9.5px; padding-bottom: 9.5px;
padding-left: 13px; padding-right: 13px;

background: linear-gradient(to left, black, #7b4397, black, #e72f60);

background-size: 300% 300%;

-webkit-animation: WelcomePageGradient 10s ease infinite;
-moz-animation: WelcomePageGradient 10s ease infinite;
animation: WelcomePageGradient 10s ease infinite;
z-index: 1;
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
border-radius: 20px;
}

.enroll-now-sold {
position: absolute;
top: 0px; left: 0;
width: 100%;

height: 100%;
color: white;
font-weight: 300;

background: linear-gradient(to right, rgba(110, 110, 110, 0.863), rgba(219, 219, 219, 0.63));
z-index: 1;

}

.enroll-text {
position: absolute;
top: 50%; right: 20px;
transform: translate(0, -50%);
right: 20px;
color: black;
font-weight: 600;
padding-top: 8.5px; padding-bottom: 8.5px;
padding-left: 12px; padding-right: 12px;
}
.closed-container {
padding: 12px;
padding-top: 18px;
padding-bottom: 18px;
border-bottom: 1px solid rgba(214, 214, 214, 0.418);
background: linear-gradient(to left, black, #7b4397, black, #e72f60);
background-size: 300% 300%;

-webkit-animation: WelcomePageGradient 10s ease infinite;
-moz-animation: WelcomePageGradient 10s ease infinite;
animation: WelcomePageGradient 10s ease infinite;
color: transparent;
background-clip: text;
text-transform: uppercase;
font-size: 8pt;
font-weight: 400;
}

.desktop {
display: none;
}

.acad-top-section {
display: block;
position: unset;
width: unset;

}

.intro-btns {
display: block;
position: relative;
margin-top: 5%;
width: 100%;
}

.intro-btn {
display: inline-block;
width: 35%;
margin: 2.5%;
padding: 2.5%;
padding-top: 3%;
padding-bottom: 3%;
text-transform: uppercase;
font-weight: 500;
font-size: 9.5pt;
color: white;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
background-color: #353535ee;
}

video.mobile-vid.a {
position: absolute;
top: 135px;
opacity: .45;
width: 100%;
}

.width-96 {
width: 96%;
margin: 0 auto;
display: block;
margin-top: -70px;
padding-left: unset; padding-right: unset;
}

video.whyus-vid {
position: relative;
display: block;
width: 100%;
}

.apprentice-page {
padding-top: 140px;
}

.smaller-bf {
font-size: 28pt;
margin-bottom: 8px;
}

.main-title {
font-size: 24pt;
text-transform: uppercase;
font-family: "Sanz-Thin", sans-serif;
position: absolute;
width: 100%;
color: white;
position: absolute;
z-index: 9999;
top: 250px;
}


.trans-back {

  font-size: 16px;
  background: white;
  color: rgb(51, 51, 51);
}
.trans-back:hover{
cursor: pointer;
}

img.acad-front-3 {
display: none;
}

img.acad-front-4 {
display: none;
}

img.acad-front {
position: relative;
width: 94%;
left: 50%;
margin-top: -37%;
padding-bottom: 20px;

transform: translate(-50%);
background-color: transparent;
display: block;
}
img.acad-front-2 {
position: relative;
width: 110%;
background: transparent;
height: auto;
left: 50%; transform: translate(-50%);
margin: 0 auto;
margin-top: 4%;
text-align: center;
display: block;
z-index: 2;
}

.course-wrap {
width: 100%;
vertical-align: top;
display: inline-block;
padding: 0;
margin: 0 auto;
background: white;
position: relative;

font-weight: 200;
font-size: 9pt;
text-align: center;
border-top: 1px solid rgba(0, 0, 0, 0.096);
border-bottom: 1px solid rgba(0, 0, 0, 0.096);
}

.review-container {
width: 26%;
background: white;
display: inline-block;
height: fit-content;
white-space: normal;
vertical-align: top;
text-align: center;
margin: 5px;
border-radius: 12px;
box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.page {

display: block;
margin-top: 110px;
position: relative;
}


.purp-back {
  
}

.entry-text-low {
color: rgba(51, 48, 51, 0.836);
font-weight: 200;
font-size: 11pt;
text-align: center;
letter-spacing: 0.5px;


}

.page {
text-align: center;
}

.desktop-pad {
padding: 0; 
}

.full-btn {
position: fixed;
z-index: 9999999;
top: 90%;
left: 50%;
width: 90%;
transform: translate(-50%, -95%);
}

.body-page {
width: 100%;
margin-top: 20px;
}

.half {
width: 100%;
position: relative;
display: block;
}

.third {
display: block;
width: 100%;

padding-bottom: 30px;
position: relative;

}


.video-name {
position: absolute;
z-index: 1000;
}


img.video-name-image {
width: 65%;
height: auto;
position: absolute;
text-align: center;
bottom: 20px;
right: 0px;
z-index: 300;
display: block;
}

.course-title-top {
  position: absolute;
  color: white;
  z-index: 999;
  bottom: 55px;
  font-family: poppins, sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  text-align: left;
  left: 10px;
  letter-spacing: -.8px;
  line-height: 1.1em;
}

.remaining-title {
color: white;
position: absolute;
bottom: 10px;
left: 10px;
text-align: left;
z-index: 300;
font-size: 10.5pt;
font-weight: 300;
text-transform: uppercase;
color: #ffffff;
font-weight: 300;
}

.mobile-banner {
position: relative;
margin-bottom: 0px;
display: block;
}

.mobile-banner-overlay {

background: linear-gradient(to bottom, black, transparent, transparent, black);
position: absolute;
top: 0;

z-index: 0;
}


.banner-overlay {
display: none;
height: 100%;
width: 100%;
position: absolute;
z-index: 100;
top: 0;left: 0;
background: linear-gradient(to bottom, rgba(0, 0, 0, 0.103), rgba(0, 0, 0, 0.418));

}
video.main-vid {
display: block;
width: 100%;
position: relative;
top: 0px;
left: 0;
height: 100%;
opacity: 1;
transform: rotate(90deg);
}

.video-container {
position: relative;
width: 100%;
height: 100vh;
min-height: 700px;
margin-top: 0px;
vertical-align: top;
overflow: hidden;

}

video.desktop-vid {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  min-height: 100%;
  min-width: 100%;
  width: auto;
}





img {
height: 100%;
}


.half {
position: relative;
display: block;
width: 100%;
overflow: hidden;
vertical-align: middle;
height: 65vh;
}

.padding-20 {
padding-top: 80px;
padding-bottom: 80px;
}

.right-navbar {
display: none;
}

.mobile-no {
display: none;
}

.mobile-yes {
display: block;
}

.section {
white-space: nowrap;
width: 100%;
overflow: hidden;
margin-top: -5px;
}

.mobile-third {
position: relative;
display: inline-block;
width: 50%;
white-space: nowrap;
vertical-align: middle;
overflow: hidden;
margin-bottom: -5px;
}

.mobile-vid {

}

video.mobile-vid {
position: relative;
top: 0px;
width: 400px;
margin: 0 auto;
text-align: center;
left: 0;
background: black;
transition: .5s;
display: none;


}

video.mobile-vid-half {
width: 50%;
display: inline-block;
margin: 0;
padding: 0;
margin-bottom: -8px;
}

.block {
width: 100%;
margin: 0 auto;
min-height: 420px;
margin-bottom: -70px;
height: fit-content;
position: relative;
background: transparent;
overflow: hidden;
vertical-align: middle;
text-align: left;
display: block;
}

.block-full {
width: 100%;
margin: 0 auto;
min-height: 60vh;
height: fit-content;
padding-top: 80px;
padding-bottom: 20px;
position: relative;
background: linear-gradient(to bottom, transparent, white, white, white, white, white);
vertical-align: middle;
text-align: left;
display: block;
}

.block-inside {
width: 100%;
margin-top: 0%;
padding-top: 0;
height: 100%;
}

.moving-gradient-2 {
background: linear-gradient(to bottom right, #e72f60, #7b4397);
background-size: 300% 300%;

-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
}

.action-button {
display: block;
position: relative;
z-index: 900000;
background-color: transparent;
width: 130px;
height: 42px;
margin: 0px auto;
margin-top: -10px;
vertical-align: middle;
font-size: 40px;
color: white;
padding: 0px;
text-align: center;
text-transform: uppercase;
font-size: 13px;
-webkit-appearance: none;
transition: .4s;
border-image: linear-gradient(to right, #005e7f, #61b6cd);
border-radius: 50px;
background-size: 100%;
background: rgb(0, 0, 0);
border: 2px transparent;
border-radius: 50px;
background-origin: border-box;
background-clip: content-box, border-box;
vertical-align: middle;
}

.action-button-inside {
display: block;
position: absolute;
z-index: 1000000;
background-color: #ffffff;
width: 120px;
height: 42px;
border-radius: 50px;
padding: 20px;

}
.action-button-text {
display: block;
position: absolute;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 9pt;
background-color:transparent;

padding-top: 11px;
padding-bottom: 11px;
vertical-align: middle;
top: 49%;
transform: translate(0, -50%);
text-align: center;
width: 99%;
color: rgb(255, 255, 255);
border-radius: 50px;
}

.acad-front-container {
position: absolute;
overflow: hidden;
width: 100%;
background: transparent;
background-color: transparent;
top: 0; left: 50%; transform: translate(-50%, -50%);
}



img.acad-front-desktop {
position: relative;
width: 120%;
left: 50%;
padding-top: 50px;
padding-bottom: 100px;

transform: translate(-50%);
background-color: transparent;
display: none;
}

.wrap {
white-space: normal;
}

.switch-container {
display: block;
width: 400px;
margin: 0 auto;
margin-bottom: 10px;
font-size: 10pt;
}

.switch-container:hover {
  cursor: pointer;
}

span.switch-left {
display: inline-block;
width: 45%;
padding: 2.5%;
text-align: center;
box-shadow: inset 0px 0px 4px rgba(0,0,0,0.5);
}

span.switch-left.selected {
display: inline-block;
width: 45%;
padding: 2.5%;
text-align: center;
background: black;
color: white;
}

span.switch-right {
display: inline-block;
width: 45%;
padding: 2.5%;
text-align: center;
box-shadow: inset 0px 0px 4px rgba(0,0,0,0.5);
}

span.switch-right.selected {
display: inline-block;
width: 45%;
padding: 2.5%;
text-align: center;
background: black;
color: white;
}

.slide-right {
transition: .5s;
}

.slide-left {
transition: .5s;
}



.width-100 {
position: relative;
display: block;
width: 100vw;
overflow: hidden;

}

.width-120 {
width: 50%;

}


.white {
color: white;
}

a.bnb {
color: black;
font-family: "Sanz-Heavy", sans-serif;
font-weight: 500;
}

.bnbt.wht {
  color: white;
}
a.waitlist {
  position: absolute;
  right: 30px;
  z-index: 99;
  top: 50%;
  
  transform: translate(0, -50%);
}
.mobile-only {
  display: none;
}

.intro-headers {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 59vh;
  z-index: 100;
}

.intro-button2 {
  position: absolute;
  

  padding-left: 14px;
  z-index: 100;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  z-index: 99999;

}

h1.entry-title {
  position: absolute;
  top: 53%; left: 50%;
  transform: translate(-50%, 0);
  width: fit-content;
  white-space: nowrap;
  font-size: 28px;
  line-height: 2.4em;
  text-align: center;
  text-transform: uppercase;
  font-family: "Sanz-Thin", sans-serif;
}

h2.entry-title {
  position: absolute;
  top: 71%; left: 50%;
  transform: translate(-50%, 0);
  width: fit-content;
  white-space: nowrap;
  font-size: 19px;
  font-weight: 500;
  font-family: "Sanz-Reg", sans-serif;
}


h4.entry-title {
  position: absolute;
  top:83%; left: 50%;
  transform: translate(-50%, 0);
  width: fit-content;
  color: black;
  white-space: nowrap;
  font-weight: 500;
  padding: 5px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: normal;
  font-family: "Sanz-Reg", sans-serif;
  background: white;
}

h4 i {
  font-size: 20pt;

  vertical-align: bottom;
  background: linear-gradient(to bottom left, rgba(3, 3, 3, 0.26), #7b4397, #7b4397, rgb(0, 0, 0), #e72f60, #e72f609c);

background-size: 200% 200%;
-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
  background-clip: text;

  -webkit-background-clip: text;
  -webkit-font-smoothing: antialiased;
  color: transparent;
}

h3.entry-title {
  position: absolute;
  top: 76.5%; left: 50%;
  transform: translate(-50%, 0);
  width: fit-content;
  color: rgba(236, 236, 236, 0.901);
  white-space: nowrap;
  font-weight: 100;
  font-size: 13px;
  letter-spacing: normal;
  font-family: "Sanz-Reg", sans-serif;
}


.moving-grad-6 {
  background: linear-gradient(80deg,  transparent, transparent, transparent, transparent, transparent, rgba(255, 255, 255, 0.242), transparent, transparent, transparent);
  -webkit-animation: slide-back-forth 10s  infinite;
  -moz-animation: slide-back-forth  10s  infinite;
  animation: slide-back-forth 10s infinite;
  width: 300%;
  left: -60%;
  height: 100vh;
  margin-top: 0px;
  position: absolute;
  z-index: 99;
}

video.highlight {
  width: 50%;
  display: inline-block;
}

.banner-top-container {
  position: fixed;
  top: 0;
  width: 100%;
  margin-top: 0px;
  overflow: hidden;
  z-index: -1;
}

img.banner-image {
  padding-top: 10px;
  filter: blur(0px);

}



.move-up {
  margin-top: -90vh;
  
}

.banner-image.move-up {
  display: inline-block;
  width: 100%;
  left: 0;
  margin-top: 50px;
  min-height: 100%;
  height: auto;
}

img.banner-image-blur {
  padding-top: 10px;
  filter: blur(5px);
  transition: .3s;
}

.banner-image-overlay {
  position: absolute;
  top: 0;
  left: 0; width: 100%;
  height: 100%;
  z-index: 0;
  background: linear-gradient(black, transparent, rgba(0, 0, 0, 0.474));
  opacity: 1;
}

.banner-image-overlay1 {
  position: absolute;
  top: 0;
  left: 0; width: 100%;
  height: 100%;
  z-index: 9;
  background: linear-gradient(rgba(0, 0, 0, 0.572), transparent, transparent, transparent);
  opacity: 1;
}


.banner-image-overlay.vid {
 background: linear-gradient(to bottom, rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.465));
 background: linear-gradient(to bottom, rgba(0, 0, 0, 0.392), rgba(0, 0, 0, 0.392), transparent);
 height: 80vh;
 width: 40%;
 position: absolute;
}

.fundamental-classes {
  position: relative;
  display: block;
  height: 100vh;

}

.banner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.banner-vid {
  position: absolute;
  width: 40%;
  margin-top: -50px;
  filter: blur(0px);
  height: 1300px;
  overflow: hidden;
  z-index: 0;
}



.banner-vid-opaque {
  position: fixed;
  height: 90vh;
  margin-top: -60px;
  filter: blur(4px);
  transition: .3s;
  width: 40%;
}

.wht-email {
  color: #f0cffc;
  font-family: "Sanz-Bold", sans-serif;
}
.sanz-bold {
  font-family: "Sanz-Bold", sans-serif;

  font-weight: 100;
  font-size: 40px;
  opacity: .8;


}

.video-inline-container {
  width: 95%;
  height: fit-content;
  position: relative;
  overflow: hidden;
  top: 0;
  vertical-align: top;
  margin-bottom: -15px;
}

video.inline-block2 {
  position: absolute;

  height: 600px;
  width: auto;
  vertical-align: top;
  top: 46.5%; transform: translate(0, -50%);
}

.more-room {
  padding-bottom: 18px;
}

.paragraph {
display: block;
position: absolute;
z-index: 99999999;
color: white;
text-align: center;
width: 90%;
margin: 0 auto;
left: 5%;
font-size: 17px;
position: absolute;
top: 340px;
}

.left-half {

  display: inline-block;
  position: relative;
  width: 40%;
  height: fit-content;
  z-index: 999999999;
  background: transparent;
}

.right-half {
  display: inline-block;
  position: relative;
  width: 60%;
  padding-top: 120px;
  margin-left: 0%;
  vertical-align: top;
  height: fit-content;
  overflow: hidden;
}

.right-half.left-10 {
  margin-left: 10%; 
  width: 50%;
}

.apprentice-page {
  position: relative;
  z-index: 9999999;
  padding-top: 40px;
  color: black;
}

.square-container {
  display: block;
  position: relative;
  height: 400px;
  width: 100%;
  overflow: hidden;

}

video.width-100 {
  position: inherit;
}

span.font-small {
  font-size: 11pt;
  padding: 5px;
  border-right: .5px solid rgba(255, 255, 255, 0.203);
  font-weight: 300;
  padding-right: 15px;
  margin-right: 5px;
  padding-left: 10px;
  margin-left: 5px;
  letter-spacing: .4px;
  font-family: "Sanz-Reg", sans-serif;


}

.desktop-like-mobile {
  position: relative;
  width: 600px;
  overflow: hidden;
  margin: 0 auto;
}

.italic.all-courses-spacing {
  margin-top: 10px;
}

.desktop-page2 {
  margin-top: 0px;
  padding-top: 0px;
  z-index: 99;
}

.left-half.banner-top-container {
  height: 855px;
  overflow: hidden;
}

.height-90 {
  height: 90vh;
}

.abs-right-half {
  position: absolute;
  top: 35%;
  width: 60%;
  display: inline-block;
  right: 0;
  font-family: "Sanz-Thin", sans-serif;
}

.abs-right-half.sub {
  top: 50%;
  width: 40%;
  right: 10%;
  font-size: 16px;
  font-family: "Sans-Reg", sans-serif;
}

.desktop-view {
  width: 600px;
  margin: 0 auto;
}

.nomargtop {
  margin-top: 0;
}

.moving-grad-8 {
  background: linear-gradient( transparent, transparent, transparent, transparent, transparent, rgba(255, 255, 255, 0.241), transparent, transparent, transparent);
  -webkit-animation: slide-back-forth 10s  infinite;
  -moz-animation: slide-back-forth  10s  infinite;
  animation: slide-back-forth 10s infinite;
  background: linear-gradient(12deg, transparent, rgb(160, 160, 160),  rgb(100, 100, 100), rgb(160, 160, 160), transparent);
  width: 160%;
  left: 0%;
  height: 3px;
  margin-top: 0px;
  position: absolute;
}

.rotate-90 {
  transform: rotate(90deg);
  z-index: 999;
  width: 330px;

  position: absolute;
  left: -120px;
}

.desktop-right-box {
  display: inline-block;
  width: 600px;
}

video.desktop-700px {
  float: right;
 height: 700px;
 max-width: 50%;
 margin: 20px;
 top: 0;
  overflow: hidden;
}

.desktop-mid {

  margin-top: 0%;
}

.title-main-a-l {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: clamp(8vw,var(--fs09),16.5vw);
  font-size: 2.3vh;
  color: white;
  position: absolute;
  top: 48vh;
  left: 100px;
  z-index: 9;
  line-height: 1em;
  text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
  z-index: 9999;
}

.title-main-l {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: clamp(8vw,var(--fs09),16.5vw);
  font-size: 10vh;
  color: white;
  position: absolute;
  top: 52vh;
  left: 100px;
  line-height: 1em;
  width: 80%;
  z-index: 99;
  text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
}

.title-main-l-moving {
  font-weight: 700;
  font-size: clamp(8vw,var(--fs09),16.5vw);
  font-size: 8ch;
  font-size: 2.3rem;
  color: white;
  position: relative;
  white-space: normal;
  background: black;
  line-height: 1em;
  z-index: 99;
  backface-visibility: hidden;
  will-change: transform;
  text-transform: uppercase;
  width:100%;
  animation: .5s fade-in;
  position: absolute;
  top: 210px;
  z-index: 999999;
  background: transparent;
  text-shadow: 3px 3px 12px rgb(0, 0, 0);

  animation: 2s fade-in
}

.title-main-l-moving-2 {
  font-weight: 700;
  font-size: clamp(8vw,var(--fs09),16.5vw);
  font-size: 8ch;
  font-size: 3.2rem;
  color: white;
  position: relative;
  white-space: normal;
  background: black;
  line-height: 1em;
  z-index: 99;
  backface-visibility: hidden;
  will-change: transform;
  text-transform: uppercase;
  width:60%;
  animation: .5s fade-in;
  position: absolute;
  top: 65vh;
  right: 30%;
  text-align: right;
  z-index: 999999;
  background: transparent;
  text-shadow: 3px 3px 12px rgb(0, 0, 0);

  animation: 1s fade-in

}

.title-main-l-moving-3 {
  font-weight: 700;
  font-size: clamp(8vw,var(--fs09),16.5vw);
  font-size: 8ch;
  font-size: 2.3rem;
  color: white;
  position: relative;
  white-space: normal;
  background: black;
  line-height: 1em;
  z-index: 99;
  backface-visibility: hidden;
  will-change: transform;
  text-transform: uppercase;
  width:90%;
  animation: .5s fade-in;
  position: absolute;
  top: 110vh;
  left: 80%; transform: translate(-50%);
  z-index: 999999;
  background: transparent;
  text-shadow: 3px 3px 12px rgb(0, 0, 0);

  animation: 1s fade-in

}

.title-main-l-moving span {

  text-transform: uppercase;
}


.title-main-l2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-size: clamp(8vw,var(--fs09),16.5vw);
  font-size: 2.3vh;
  color: white;
  position: absolute;
  top: 74vh;
  left: 100px;
  line-height: 1em;
  text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
  z-index: 99;
}

.title-main-l3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: clamp(8vw,var(--fs09),16.5vw);
  font-size: 2.1vh;
  color: white;
  position: absolute;
  top: 78vh;
  left: 100px;
  line-height: 1.3em;
  text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
  z-index: 99;
}



.aam-logo {
  width: 105px;
  height: 105px;
  position: absolute;
  left: 80%; transform: translate(-50%);
  z-index: 99999;
  top: 74vh;
}

.front-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
  background: linear-gradient(rgba(0, 0, 0, 0.635), transparent, transparent, transparent, rgba(0, 0, 0, 0.274), black)
}

.front-overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
  transition: .3s ease-in cubic-bezier(0.075, 0.82, 0.165, 1);
  animation: .4s fade-in;
  background: linear-gradient(rgba(0, 0, 0, 0.635), transparent, rgba(0, 0, 0, 0.862), black)
}

.front-overlay3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
  background: linear-gradient(rgba(0, 0, 0, 0.635), transparent, transparent, transparent, rgba(0, 0, 0, 0.274), black);
  background: black;
  transition: .5s ease-in;
}

.front-video-container {
  height: 100vh;
  min-width: 1500px;
  overflow: hidden;
}

.front-video-container-2 {
  height: 100vh;
  overflow: hidden;

}

.kayla-container {
  animation: 1s slide-in-bottom;
  width: 62vh;
  position: absolute;
  margin-left: 84vh;
  top: 0;
  background: radial-gradient(#f43ea8, rgba(128, 0, 128, 0.236), transparent);
  background: linear-gradient(to top, transparent, #f43ea862, transparent);
  z-index: 99;
}

.kayla-container:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 0px 100px 205px;
  border-color: transparent transparent #000000 transparent;
}

.jen-container {
  animation: 1s slide-in-left;
  width: 65vh;
  margin-top: -66vh;
  margin-left: 110vh;
  position: relative;
  z-index: 999999;
}


.jocelyn-container {
  animation: 1s slide-in-left;
  width: 42vh;
  left: 44vh;
  margin-top: -100px;
  margin-right: 0;
  position: relative;
  z-index: 9999;
  
 
}

.jocelyn-container:after {
content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 0px 60px 405px;
    border-color: transparent transparent #000000 transparent;
}

.jacqueline-container {
  animation: 1s fade-in;
  position: relative;
  width: 68.5vh;
  left: 50vh;
  margin-top: -16vh;
  
  z-index: 999999;
  animation: 1s slide-in-bottom;
  background: linear-gradient(to top, transparent, #f43ea86e, #f43ea836, transparent);


}

.jacqueline-container:after {
  content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 600px 00px 00px;
      border-color: transparent transparent #000000 transparent;
  }

  .jacqueline-container-2 {

    position: relative;
  width: 68.5vh;
  left: 50vh;
  margin-top: -16vh;
  
  z-index: 999999;
    z-index: 9;
   transition: 2s;
  
  
  }
  
  .jacqueline-container-2:after {
    content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 600px 500px 00px;
        border-color: transparent transparent #000000 transparent;
        transition: 2s;
    }

.jen-container-2 {
  transition: 2s;
  width: 40vh;
  margin-top: 80px;
  left: 50vh;
  display: inline-block;
  position: relative;
  z-index: 9999;
}


.jen-container-3 {
  transition: 2s;
  width: 180px;
  height: auto;
  display: inline-block;
  position: relative;
  z-index: 999;
  animation: 2s slide-in-right;
}

.best-artists-row {
  white-space: nowrap;
  overflow-x: scroll;
  width: 2160px;


}

.best-artists-row-container {

  -webkit-animation-name: move;
  -webkit-animation-duration: 50s;
  -webkit-animation-delay: .7s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: left;
  -webkit-animation-timing-function: linear;
}



.best-artists {

 
}

.black-background {
  background-color: black;
}





@media (max-width: 800px) {

  .front-video-container {
    height: 100vh;
    min-width: unset;
    overflow: hidden;
  }
  
  .front-video-container-2 {
    height: 100vh;
    overflow: hidden;
  
  }

  .title-main-l-moving-2 {
    font-weight: 700;
    font-size: clamp(8vw,var(--fs09),16.5vw);
    font-size: 8ch;
    font-size: 2.3rem;
    color: white;
    position: relative;
    white-space: normal;
    background: black;
    line-height: 1em;
    z-index: 99;
    backface-visibility: hidden;
    will-change: transform;
    text-transform: uppercase;
    width:60%;
    animation: .5s fade-in;
    position: absolute;
    top: 370px;
    right: 20px;
    text-align: right;
    z-index: 999999;
    background: transparent;
    text-shadow: 3px 3px 12px rgb(0, 0, 0);
  
    animation: 1s fade-in
  
  }
  
  .title-main-l-moving-3 {
    font-weight: 700;
    font-size: clamp(8vw,var(--fs09),16.5vw);
    font-size: 8ch;
    font-size: 2.3rem;
    color: white;
    position: relative;
    white-space: normal;
    background: black;
    line-height: 1em;
    z-index: 99;
    backface-visibility: hidden;
    will-change: transform;
    text-transform: uppercase;
    width:100%;
    animation: .5s fade-in;
    position: absolute;
    top: 700px;
    right: unset;
    left: 54%;
    z-index: 999999;
    background: transparent;
    text-shadow: 3px 3px 12px rgb(0, 0, 0);
  
    animation: 1s fade-in
  
  }
  


.kayla-container {
  animation: 1s slide-in-bottom;
  width: 92%;
  position: absolute;
  margin-left: 28%;
  top: 0;
  background: radial-gradient(#f43ea8, rgba(128, 0, 128, 0.236), transparent);
  background: linear-gradient(to top, transparent, #f43ea862, transparent);
  z-index: 99;
}

.kayla-container:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 0px 100px 205px;
  border-color: transparent transparent #000000 transparent;
}

.jen-container {
  animation: 1s slide-in-left;
  width: 65%;
  margin-top: -150px;
  margin-left: 25%;
  position: relative;
  z-index: 9999999;
}


.jocelyn-container {
  animation: 1s slide-in-left;
  width: 72%;
  left: -24%;
  margin-top: -100px;
  margin-right: 0;
  position: relative;
  z-index: 9999;
  
 
}

.jocelyn-container:after {
content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 0px 60px 405px;
    border-color: transparent transparent #000000 transparent;
}

.jacqueline-container {
  animation: 1s fade-in;
  position: relative;
  width: 100%;
  left: 0;
  margin-top: -130px;
  
  z-index: 999999;
  animation: 1s slide-in-bottom;
  background: linear-gradient(to top, transparent, #f43ea86e, #f43ea836, transparent);


}

.jacqueline-container:after {
  content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 600px 00px 00px;
      border-color: transparent transparent #000000 transparent;
  }

  .jacqueline-container-2 {

    position: relative;
    width: 96%;
    left: 4%;
    margin-top: -130px;
    z-index: 9;
   transition: .8s;
  
  
  }
  
  .jacqueline-container-2:after {
    content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 600px 500px 00px;
        border-color: transparent transparent #000000 transparent;
        transition: 2s;
    }

.jen-container-2 {
  transition: .8s;
  width: 180px;
  margin-top: 200px;
  display: inline-block;
  position: relative;
  z-index: 9999;


  left: unset;

}


.jen-container-3 {
  transition: 2s;
  width: 180px;
  height: auto;
  display: inline-block;
  position: relative;
  z-index: 999;
  animation: 2s slide-in-right;
}


  .title-main-l2 {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    font-size: clamp(8vw,var(--fs09),16.5vw);
    font-size: 3vw;
    color: white;
    position: absolute;
    top: 80vh;
    left: 30px;
    line-height: 1em;
    text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
    z-index: 99;
  }
  
  .title-main-l3 {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: clamp(8vw,var(--fs09),16.5vw);
    font-size: 2.4vw;
    color: white;
    position: absolute;
    top: 83vh;
    left: 30px;
    line-height: 1.3em;
    text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
    z-index: 99;
  }
  
  .aam-logo {
    width: 105px;
    height: 105px;
    position: absolute;
    left: 17em; transform: translate(-50%);
    z-index: 99999;
    top: 60vh;
  }


  .title-main-a-l {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    font-size: clamp(8vw,var(--fs09),16.5vw);
    font-size: 3.6vw;
    color: white;
    position: absolute;
    top: 41vh;
    left: 30px;
    z-index: 9;
    line-height: 1em;
    text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
    z-index: 9999;
  }
  
  .title-main-l {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    font-size: clamp(8vw,var(--fs09),16.5vw);
    font-size: 12vw;
    color: white;
    position: absolute;
    top: 45vh;
    left: 30px;
    line-height: 1em;
    z-index: 99;
    width: 60%;
    text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.481);
  }

  video.desktop-700px {
    float: unset;
   height: unset;
   max-width: unset;
   margin: unset;
    overflow: hidden;
  }
  
  .desktop-mid {
    vertical-align: unset;
    margin-top: unset;
  }

  img.inkbrush-image {
    width: 100%;
  }

  img.inkbrush-image.inline-block {
    width: 100%; display: inline-block;
  }

  .moveup {
    margin-top: -200px;
  }

  .desktop-view {
    width: unset;
    margin: 0 auto;
  }

  .abs-right-half {
    position: absolute;
    top: 20%;
    width: 60%;
    display: none;
    right: 0;

  }
  
  .height-90 {
    height: unset;
  }

  .desktop-override {
    background-color: transparent;
  }

  .left-half.banner-top-container {
    height: 90vh;
    overflow: hidden;
  }



  .banner-image.move-up {
  display: block;
  width: auto;
  left: 50%; transform: translate(-20%, -8%);
  margin-top: -160px;
  height: 120%;;
}

  .space-title-small {
    position: relative;
    font-size: 9pt;
    font-weight: 200;
    padding-top: 0px;
    display: block;
    padding-bottom: 10px;
    font-family: "Sanz-Thin", sans-serif;
    text-transform: uppercase;
    color: #f5f5f5;
    }

  .desktop-like-mobile {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }

  span.font-small {
    font-size: 9pt;
    padding: 7px;
    border-right: .5px solid rgba(255, 255, 255, 0.373);
    border-left: .5px solid rgba(255, 255, 255, 0.273);
    border-left: none;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding-right: 10px;
    margin-right: 5px;
    padding-left: 10px;
    margin-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.173), rgba(255, 255, 255, 0.053), rgba(255, 255, 255, 0.073));
  }




  .banner-vid {
    position: fixed;
    height: 40vh;
    width: 100%;
    margin-top: -45px;
    filter: blur(0px);
    overflow: unset;
  }

  .banner-vid-opaque {
    position: fixed;
    height: 40vh;
    width: 100%;
    margin-top: -60px;
    filter: blur(6px);
    overflow: unset;
  }

  .left-half {
    width: 100%;
    display: block;
    height: unset;
  }

  .right-half {
    width: 100%;
    display: block;
    height: unset;
    margin-left: unset;
    padding-top: 210px;
    background: transparent;
  }

  .right-half.left-10 {
    margin-left: unset; 
    width: 100%;
  }
  .banner-image-overlay.vid {
    background: rgba(0, 0, 0, 0.392);
    height: 90vh;
    width: 100%;
   }

   .paragraph {
    display: block;
    position: absolute;
    z-index: 99999999;
    color: white;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    top: -120px;
    left: 50%; transform: translate(-50%);
    font-size: 13px;
    padding-top: 40px;

    }

  .move-up {
    margin-top: -20vh;
  }

  .banner-top-container {
    position: fixed;
    top: 0;
    margin-top: 0px;
    overflow: hidden;
    z-index: -1;
  }

  img.banner-image {
    padding-top: 10px;
    filter: blur(0px);
  }
  .sanz-bold {
    font-family: "Sanz-Bold", sans-serif;

    font-weight: 200;
    font-size: 30px;
  
  
  }
  

  .video-inline-container {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
  }

  video.inline-block2 {
    position: absolute;
    width: 100%;
    top: 50%; transform: translate(0, -50%);
  }

  .moving-grad-4 {
    background: linear-gradient(80deg,  transparent, transparent, transparent, transparent, transparent, rgba(255, 255, 255, 0.241), transparent, transparent, transparent);
    -webkit-animation: slide-back-forth 10s  infinite;
    -moz-animation: slide-back-forth  10s  infinite;
    animation: slide-back-forth 10s infinite;
    width: 300%;
    left: -60%;
    height: 30px;
    margin-top: -3px;
    position: absolute;
  }

  .moving-grad-6 {
    background: linear-gradient(80deg,  transparent, transparent, transparent, transparent, transparent, rgba(255, 255, 255, 0.231), transparent, transparent, transparent);
    -webkit-animation: slide-back-forth 10s  infinite;
    -moz-animation: slide-back-forth  10s  infinite;
    animation: slide-back-forth 10s infinite;
    width: 300%;
    left: -60%;
    height: 100vh;
    margin-top: 0px;
    position: absolute;
    z-index: 99;
  }

  .video-container {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 700px;
    margin-top: 0px;
    vertical-align: top;
    overflow: hidden;
    
    }
    
    video.desktop-vid {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      min-height: 100%;
      min-width: 100%;
      width: auto;
    }

    h1.entry-title {
      position: absolute;
      top: 45%; left: 50%;
      transform: translate(-50%, 0);
      width: 95%;
      white-space: normal;
      font-size: 27.5px;
      font-family: "Sanz-Thin", sans-serif;
      line-height: 1.4em;
      margin: 0;
      padding: 0;
      font-weight: lighter;
      letter-spacing: -8.8px;
      text-transform: uppercase;
     
    }
    
    h2.entry-title {
      position: absolute;
      top: 60.8%; left: 50%;
      transform: translate(-50%, 0);
      width: 80%;
      white-space: normal;
      font-size: 13px;
      font-weight: 500;
      font-family: "Sanz-Reg", sans-serif;
      margin: 0;
      padding: 0;
      z-index: 99;
      color: white;
      z-index: 999;
      opacity: 1;
      background: radial-gradient(rgba(0, 0, 0, 0.09), transparent);
    }
    
    h3.entry-title {
      position: absolute;
      top: 67%; left: 50%;
      transform: translate(-50%, 0);
      width: 80%;
      color: rgb(228, 228, 228);
      white-space: normal;
      font-weight: 500;
      font-size: 10.5px;
      letter-spacing: normal;
      font-family: "Sanz-Thin-Italic", sans-serif;
      z-index: 99;
      margin: 0;
      padding: 0;
      z-index: 99;
      background: radial-gradient(rgba(0, 0, 0, 0.081), transparent);
    }

    h4.entry-title {
      position: absolute;
      top:76%; left: 50%;
      transform: translate(-50%, 0);
      width: fit-content;
      color: black;
      white-space: nowrap;
      font-weight: 500;
      font-size: 12px;
      padding: 5px;
      padding-left: 14px;
      padding-right: 14px;
      border-radius: 20px;
      text-transform: uppercase;
      letter-spacing: normal;
      font-family: "Sanz-Reg", sans-serif;
      background: white;
      z-index: 99;
    }
    
    h4 i {
      vertical-align: bottom;
        font-size: 15pt;

    }

  .desktop-sxn {
    display: block;

    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  

  .mobile-only {
    display: block;
  }

  .l-r-20 {
    position: relative;
    width: 100%;
    display: inline-block;
    background: black;
    margin-top: -140px;
    padding-top: 0;
    height: 112vh;
    vertical-align: top;
  }

  button.intro-button-b {
    float: unset;
    padding-left: 15px;
  }

  .desktop-page {
    margin-top: 0;
    min-height: unset;
    min-width: 100%;
  }

  .desktop-page2 {
    margin-top: 0px;
    padding-top: 0px;
    z-index: 99;
   
  }

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-family: "Sanz-Reg", sans-serif;
  letter-spacing: 0px;
  text-transform: none;
}

video.main-vid {
display: block;
width: 105%;
position: relative;
top: 0px;
left: 0%;
height: 100%;
opacity: 1;
}

video.main-vid.third {
margin-top: 40px;
height: 100%;
left: unset;
opacity: 1;
}

video.main-vid.banner-vid {
position: absolute;
display: block;
top: 50%; left: 50%; transform: translate(-50%, -50%);
}

.banner {
display: block;
padding: 20px;
margin: 20px;
}

.banner-overlay {
display: block;
height: 100%;
width: 100%;
position: absolute;
z-index: 100;
top: 0;left: 0;
background: linear-gradient(to bottom, rgba(0, 0, 0, 0.068), rgba(0, 0, 0, 0.418));

}

.desktop-only {
display: none;
}

.video-name {
position: relative;
z-index: 200;
}



img.video-name-image.a {
width: 75%;
position: absolute;
text-align: center;
top: 4px;
right: 30px;
}

img.video-name-image.b{
width: 75%;
position: absolute;
text-align: center;
top: 34px;
right: 0px;
}

img.video-name-image.c{
width: 75%;
position: absolute;
text-align: center;
top: 4px;
right: 0px;
}

.acad-top-section {
display: inline;
position: relative;
width: 50%;
}



img.acad-front-2 {
position: relative;
width: 36%;
background: transparent;
height: auto;
margin: 0 auto;
margin-top: 2%;
text-align: center;
z-index: 2;
display: none;
}

.acad-front-container {
position: relative;
overflow: hidden;
width: 100%;
z-index: 10000;
display: block;
}

img {
width: 100%;
}

.page {

display: block;
margin-top: 110px;
position: relative;
}

.section {
display: block;
position: relative;
margin: 0;
height: fit-content;
padding: 0;
}

.section3 {
position: relative;
z-index: 100;
padding-top: 90px;
padding-bottom: 30px;
}

.entry-text-low {
color: rgba(51, 48, 51, 0.836);
font-weight: 200;
font-size: 18pt;
text-align: center;
letter-spacing: 0.5px;


}

img.acad-front {
position: relative;
z-index: 100;
width: 76%;
left: 0%;
top: 0px;
background: transparent;
display: none;
}

img.acad-front-desktop {
position: relative;
z-index: 1000;
width: 125%;
left: 50%;
margin-top: -7%;

padding-bottom: -1%;
background: transparent;
transform: translate(-50%);
display: block;
}
.black-friday-special {
font-size: 40pt;
font-weight: 300;


background: linear-gradient(to left, rgba(3, 3, 3, 0.26), #772dac, #772dac, rgb(0, 0, 0), #f43ea8, #f43ea8);

background-size: 200% 200%;
-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
padding-top: 10px;
padding-bottom: 5px; 
letter-spacing: 0;
background-clip: text;

-webkit-background-clip: text;
-webkit-font-smoothing: antialiased;
color: transparent;


font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

}

.black-friday-special-2 {
font-size: 32pt;
font-weight: 200;
background: linear-gradient(to right, rgb(79, 40, 87),  rgb(241, 42, 118));
padding-top: 7px;
padding-bottom: 5px; 
letter-spacing: -0.5px;
background-clip: text;
-webkit-background-clip: text;
-webkit-font-smoothing: antialiased;
color: transparent;

}

.j {
text-align: justify;
}

.uppercase {
text-transform: uppercase;

}

.upcoming-dates {
background: black;
color: white;
}


.slogan {
font-size: 18pt;
font-weight: 300;
font-style: italic;
padding-bottom: 20px;
margin-top: -20px;
}

.action-button {
display: block;
position: relative;
z-index: 900000;
background-color: transparent;
width: 140px;
height: 46px;
margin: 0px auto;
margin-top: -20px;
vertical-align: middle;
font-size: 40px;
color: white;
padding: 0px;
text-align: center;
text-transform: uppercase;
font-size: 13px;
-webkit-appearance: none;
transition: .4s;
  border-image: linear-gradient(to right, #005e7f, #61b6cd);
  border-radius: 50px;
  background-size: 100%;
  background: rgb(0, 0, 0);
  border: 2px transparent;
  border-radius: 50px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  vertical-align: middle;
}

.action-button::after {
z-index: -100000;
border-radius: 50px;
content: '';
display: block;    
position: absolute;
top: -1px;
left: -1px;
width: calc(100% + 2.5px);
height: calc(100% + 2px);
transform: translateZ(-1px);
background: linear-gradient(306deg, black, #7b4397, black, #e72f60);
background-size: 400% 400%;

-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
}

.action-button-inside {
display: block;
position: absolute;
z-index: 1000000;
background-color: #ffffff;
width: 140px;
height: 46px;
border-radius: 50px;
padding: 20px;

}


.action-button-text {
display: block;
position: absolute;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 11pt;
background-color: #ffffff;

padding-top: 14px;
padding-bottom: 13px;
vertical-align: middle;
top: 49%;
transform: translate(0, -50%);
text-align: center;
width: 99%;
color: rgb(0, 0, 0);
border-radius: 50px;
}

.more-btn {
color: #7b4397;

padding-top: 0px;
padding-bottom: 0px;
padding: 5px;
margin: 0 auto;
letter-spacing: -0.5px;
width: 150px;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 10pt;
text-transform: uppercase;
font-weight: 300;

border-radius: 25px;
padding-left: 10px;
padding-right: 10px;
text-align: center;
background-color: transparent;
width: fit-content;
font-weight: 600;
background: linear-gradient(to right, rgba(79, 40, 87, 0.973),  rgb(241, 42, 118));
padding-top: 0px;
letter-spacing: -0.5px;
color: rgb(255, 255, 255);
margin-top: 10px;
padding-bottom: 40px;



background:
  -moz-linear-gradient(
  -72deg,
  #dedede,
  #ffffff 16%,
  #dedede 21%,
  #ffffff 24%,
  #521f66 27%,
  #dedede 36%,
  #ffffff 45%,
  #ffffff 60%,
  #dedede 72%,
  #ffffff 80%,
  #dedede 84%,
  #a78eac
);
background:
-webkit-linear-gradient(
  -72deg,
  #dedede,
  #ffffff 16%,
  #dedede 21%,
  #ffffff 24%,
  #521f66 27%,
  #dedede 36%,
  #ffffff 45%,
  #ffffff 60%,
  #dedede 72%,
  #ffffff 80%,
  #dedede 84%,
  #a78eac
);
background:
-o-linear-gradient(
  -72deg,
  #dccbdd,
  #ffffff 16%,
  #dedede 21%,
  #ffffff 24%,
  #521f66 27%,
  #dedede 36%,
  #ffffff 45%,
  #ffffff 60%,
  #dedede 72%,
  #ffffff 80%,
  #dedede 84%,
  #a78eac
);
background:
linear-gradient(
  -72deg,
  #dedede,
  #ffffff 16%,
  #dedede 21%,
  #ffffff 24%,
  #521f66 27%,
  #dedede 36%,
  #ffffff 45%,
  #ffffff 60%,
  #dedede 72%,
  #ffffff 80%,
  #dedede 84%,
  #a78eac
);
background-clip: text;
-webkit-background-clip: text;
}

.space-box {
padding-top: -80px;
padding-bottom: 20px;
font-weight: 300;
}

.small {
color: rgba(0, 0, 0, 0.5);


letter-spacing: 0;
background-clip: text;
background-size: 100% 100%;
-webkit-background-clip: text;
-webkit-font-smoothing: antialiased;
color: #ffffffbd;
font-size: 10pt;
font-weight: 300;
font-family: "Sanz-Bold", sans-serif;
}

.smaller {
font-size: 10pt;
font-weight: 300;
line-height: 1.6em;
letter-spacing: 0.8;
}

.c {
text-align: center;
margin: 0 auto;
}

.white-back-gradient-t {
margin-top: -50px;
padding-top: 50px;
width: 100%;
z-index: 99;
height: 0px;
position: absolute;
background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.397), rgba(255, 255, 255, 0.699), rgba(255, 255, 255, 0.897), white);
}

.white-back-gradient-b {
margin-top: -22px;
padding-bottom: 0px;
width: 100%;
z-index: 99;
height: 5px;
position: absolute;
background: linear-gradient(to top, transparent, rgba(255, 255, 255, 0.397), rgba(255, 255, 255, 0.699), rgba(255, 255, 255, 0.897), white);
}

.hidden {
display: none;
}

.enroll-now {
position: absolute;
top: 20px; right: 20px;
color: white;
font-weight: 500;
padding-top: 9px; padding-bottom: 9px;
padding-left: 13px; padding-right: 13px;

background: linear-gradient(to left, black, #7b4397, black, #e72f60);

background-size: 300% 300%;

-webkit-animation: WelcomePageGradient 10s ease infinite;
-moz-animation: WelcomePageGradient 10s ease infinite;
animation: WelcomePageGradient 10s ease infinite;
z-index: 0;
border-radius: 20px;
font-family: poppins, sans-serif;
}

.course-wrap {
width: 27%;
vertical-align: top;
height: auto;
padding: 0;
margin: 1%;
position: relative;
display: inline-block;
font-weight: 200;
font-size: 9pt;
text-align: center;
margin-bottom: 1%;
}

.course-wrap-inside {
width: 100%;
margin: 0 auto;
padding-top: 0%;
padding-bottom:0%;
position: relative;
display: block;


}


img.course {
position: relative;
width: 100%;
border-top-left-radius: 12px;
border-top-right-radius: 12px;
margin-bottom: 5px;
}

.gray-wrap {
background: linear-gradient(to bottom, black, black );
background: white;
padding: 20px;
padding-left: 0;
padding-right: 0;
font-size: 10pt;
font-weight: 500;
position: relative;
top: 0; text-align: left;
z-index: 0;
width: 100%;
color: rgba(0, 0, 0, 0.815);
text-transform: uppercase;

}

.gray-wrap.list {
background: white;
color: rgba(0, 0, 0, 0.507);
}

.gray-wrap .upcoming {
font-size: 8pt;
font-weight: 300;
padding-left: 10px;
color: #000000a6;

}

.slogan2 {
color: #ffffffa6;
font-weight: 300;
}

.gray-wrap .month {
font-size: 14pt;
padding-left: 10px;
text-transform: uppercase;
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-weight: 600;
color: white;
color: rgb(0, 0, 0);
}

.year {
padding-left: 10px;
margin-top: -1px;
font-size: 12pt;
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-weight: 600;
color: black;
}
.gray-back {
background-color: #f9f9f9;
padding-top: 40px;
padding-bottom: 40px;
width: 100%;
}


.purp-back {
  margin-top: 0;
}

.blk-back {
background: rgb(14, 14, 14);
}

.review-row {
white-space: nowrap;
overflow: hidden;
overflow-x: scroll;
}

.review-container {
width: 16%;
background: white;
display: inline-block;
height: fit-content;
white-space: normal;
vertical-align: top;
text-align: center;
margin: 5px;
border-radius: 12px;
border-radius: 0px;
box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

i {
color: inherit;
}

*::-webkit-scrollbar {
display: none;
}

.review-inside {
width: 90%;
text-align: left;
margin: 0 auto;
padding: 15px;
color: rgb(70, 70, 70);

}

.footer {
font-size: 7.5pt;
color: rgb(70, 70, 70);
padding: 10px;
}

.drop {
padding-top: 12px;
padding-bottom: 12px;
border-bottom: 1px solid rgba(201, 201, 201, 0.623);
animation: fade-in 2s;
}

span.add {
position: absolute;
right: 20px;
}

span.plus {
position: absolute;
right: 20px;
background: linear-gradient(to left, black, #7b4397, black, #e72f60);
background-size: 300% 300%;

-webkit-animation: WelcomePageGradient 10s ease infinite;
-moz-animation: WelcomePageGradient 10s ease infinite;
animation: WelcomePageGradient 10s ease infinite;
color: transparent;
background-clip: text;
text-transform: uppercase;
font-size: 8pt;
font-weight: 400;
}


.mobile-video-container {
position: fixed;

margin-top: 20px;
width: 100%;
opacity: .1;
z-index: 1;

}

.vid-container {
position: absolute;
top: 0px;
left: 0;
width: 100%;
height: 100vh; overflow: hidden;
}

video.mobile-vid.a {
position: absolute;
top: 90px;
opacity: .45;
width: 100%;
}




.light {
font-size: 7.5pt;
color: rgba(70, 70, 70, 0.76);
animation: fade-in 2s;
}

.entry {
background: linear-gradient(to right, rgba(85, 85, 85, 0.973), rgba(95, 95, 95, 0.795));
background-clip: text;
-webkit-background-clip: text;
color: transparent;
margin-top: 10px;
font-size: 14px;
color: rgba(0, 0, 0, 0.887);
width: 90%;
margin: 0 auto;
}

.course-detail-page {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100vh;
overflow: hidden;
overflow-y: scroll;
background-color: white;
z-index: 9999;
}

.pad-top-90 {
padding-top: -70px;
}

.padding-bottom-20 {
margin-top: 30vh;
padding-bottom: 0px;
background: transparent;
color: black;
width: 100%;
margin-bottom: 0px;

}

.spacebar {
height: 4px;
}

.course-page-inside {
height: fit-content;
padding-bottom: 20vh;
width: 100%;
}

img.full-op {
opacity: 1;
}

.full-btn {
position: fixed;
z-index: 9999999;
top: 90%;
left: 50%;
width: 40%;

transform: translate(-50%, -95%);
}

.question-wrap {
border-bottom: 1px solid rgb(209, 209, 209);
width: 96%;
margin: 0 auto;
font-size: 14px;
}

.less-bottom {
  margin-bottom: -100px;
}

.faq-page {
padding-top: 65px;

}

.class-page {
padding-top: 65px;
text-transform: uppercase;
background-color: #f7f7f7;
}

span.right-angle {
position: absolute;
right: 20px;
}

.nav-link-btn-a {
font-size: 14pt;
font-weight: 200;
color: white;
letter-spacing: 0.5px;
animation: slide-in-right .8s;
}

.nav-link-btn-b {
font-size: 14pt;
font-weight: 200;
color: white;
letter-spacing: 0.5px;
animation: slide-in-right 1s;
}

.nav-link-btn-c {
font-size: 14pt;
font-weight: 200;
color: white;
letter-spacing: 0.5px;
animation: slide-in-right 1.2s;
}

.rotate-45l {
transform: rotate(-45deg) translate(-8px, 8px); 
transition: .4s;
background-color: white;
}

.rotate-45r {
transform: rotate(45deg) translate(3px, 2px);
transition: .4s;
background-color: white;
}

.class-wrap-2 {
text-transform: uppercase;
margin: 20px;
background-color: white;
padding-bottom: 20px;
text-align: center;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.course-order-header {
background: white;
color: #4d4d4d;
font-size: 18pt;
padding-top: 10px;
padding-bottom: 10px;
}

.body-page {
width: 700px;
margin: 0 auto;
}

.body-page-700 {
width: 700px;
width: 0 auto;
}



.half {
position: relative;
display: inline-block;
width: 50%;
overflow: hidden;
vertical-align: middle;
padding-top: 0;
text-align: center;

}

.right {
z-index: 99;
background: linear-gradient(to right, #e72f60, white);
margin-left: -59px;
}
.vert-top {
vertical-align: top;
}


.details {
background: #f4f4f4;
color: black;
text-align: left;
padding-top: 10px;
padding-left: 10px;
padding-right: 20px;
padding-bottom: 20px;
}

.class-cost {
padding-top: 5px;
padding-bottom: 10px;
font-size: 9.5pt;
font-family: "Sanz-Reg", sans-serif;
color:rgb(43, 43, 43)
}

.class-detail-title {
text-transform: uppercase;
font-size: 8.5pt;
font-weight: 400;
font-family: "Sanz-Black", sans-serif;
}
.desktop-pad {
padding: 40px; 
}

.mobile-no {
display: block;
}

.mobile-yes {
display: none;
}

.closed-container {
padding: 12px;
padding-top: 18px;
padding-bottom: 18px;
border-bottom: 1px solid rgba(214, 214, 214, 0.418);
background: linear-gradient(to left, black, #7b4397, black, #e72f60);
background-size: 300% 300%;

-webkit-animation: WelcomePageGradient 10s ease infinite;
-moz-animation: WelcomePageGradient 10s ease infinite;
animation: WelcomePageGradient 10s ease infinite;
color: transparent;

text-transform: uppercase;
color: rgba(0, 0, 0, 0.918);
font-size: 8pt;
background: rgba(255, 255, 255, 0.966);
font-weight: 400;
}

.closed-container:hover {
cursor: pointer;
}

/* additions */


.review-container {
width: 86%;
background: white;
display: inline-block;
height: fit-content;
white-space: normal;
vertical-align: top;
text-align: center;
margin: 5px;
border-radius: 12px;
border-radius: 0;
box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.desktop-only {
  display: block;
}
.page {

display: block;
margin-top: 110px;
position: relative;
}


.purp-back {

}

.entry-text-low {
color: rgba(51, 48, 51, 0.836);
font-weight: 200;
font-size: 11pt;
text-align: center;
letter-spacing: 0.5px;


}

.page {
text-align: center;
}

.desktop-pad {
padding: 0; 
}

.full-btn {
position: fixed;
z-index: 9999999;
top: 90%;
left: 50%;
width: 90%;
transform: translate(-50%, -95%);
}

.section4 {
text-transform: uppercase;
font-size: 9pt;
}

.body-page {
width: 100%;
padding-top: 60px;
text-transform: uppercase;
}

.half {
width: 100%;
position: relative;
display: block;
}




.video-name {
position: absolute;
z-index: 1000;
}


img.video-name-image {
width: 75%;
height: auto;
position: absolute;
text-align: center;
top: 36px;
right: 0px;
z-index: 300;
display: block;
}



.remaining-title.a{
color: white;
position: absolute;
top: 50px;
right: 60px;
z-index: 300;
font-size: 10.5pt;
font-weight: 300;
text-transform: uppercase;
color: white;
font-weight: 300;
}

.mobile-banner {
position: relative;
height: 100%;
width: 100%;
}


.mobile-banner-overlay {

background: linear-gradient(to bottom, transparent, #592772);
position: absolute;
top: 0px;
width: 100%;
height: 100%;
padding-bottom: 10px;
z-index: 0;
display: block;
}


.banner-overlay {
display: none;
height: 100%;
width: 100%;
position: absolute;
z-index: 100;
top: 0;left: 0;
background: linear-gradient(to bottom, rgba(0, 0, 0, 0.103), rgba(0, 0, 0, 0.418));

}
video.main-vid {
display: block;

position: relative;
top: 0px;
left: 0;

opacity: 1;
transform: rotate(90deg);
}




img {
height: 100%;
}


.half {
position: relative;
display: block;
width: 100%;
overflow: hidden;
vertical-align: middle;
height: 65vh;
}

.padding-20 {
padding-top: 80px;
padding-bottom: 80px;
}

.right-navbar {
display: none;
}

.mobile-no {
display: none;
}

.mobile-yes {
display: block;
}

.section {
white-space: nowrap;
width: 100%;
overflow: hidden;
margin-top: -5px;
}

.mobile-third {
position: relative;
display: inline-block;
width: 50%;
white-space: nowrap;
vertical-align: middle;
overflow: hidden;
margin-bottom: -5px;
}



video.mobile-vid {
  display: block;
position: relative;


}

video.mobile-vid-half {


}

.block {
width: 100%;
margin: 0 auto;
min-height: 80vh;
margin-bottom: -70px;
height: fit-content;
position: relative;
background: transparent;
overflow: hidden;
vertical-align: middle;
text-align: left;
width: 50%;
display: inline-block;
}

.block-full {
width: 100%;
margin: 0 auto;
min-height: 60vh;
height: fit-content;
padding-top: 20px;
padding-bottom: 20px;
position: relative;
background: linear-gradient(white, transparent);;
vertical-align: middle;
text-align: left;
display: block;

}

.block-inside {
width: 100%;
margin-top: 0%;
padding-top: 0;
height: 100%;
}

.moving-gradient-2 {
background: linear-gradient(to bottom right, #e72f60, #7b4397);
background-size: 300% 300%;

-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
}

.action-button {
display: block;
position: relative;
z-index: 900000;
background-color: transparent;
width: 130px;
height: 42px;
margin: 0px auto;
margin-top: -10px;
vertical-align: middle;
font-size: 40px;
color: white;
padding: 0px;
text-align: center;
text-transform: uppercase;
font-size: 13px;
-webkit-appearance: none;
transition: .4s;
border-image: linear-gradient(to right, #005e7f, #61b6cd);
border-radius: 50px;
background-size: 100%;
background: rgb(0, 0, 0);
border: 2px transparent;
border-radius: 50px;
background-origin: border-box;
background-clip: content-box, border-box;
vertical-align: middle;
}

.action-button-inside {
display: block;
position: absolute;
z-index: 1000000;
background-color: #ffffff;
width: 120px;
height: 42px;
border-radius: 50px;
padding: 20px;

}
.action-button-text {
display: block;
position: absolute;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 9pt;
background-color:transparent;

padding-top: 11px;
padding-bottom: 11px;
vertical-align: middle;
top: 49%;
transform: translate(0, -50%);
text-align: center;
width: 99%;
color: rgb(255, 255, 255);
border-radius: 50px;
}

.acad-front-container {
position: absolute;
overflow: hidden;
width: 100%;
background: transparent;
background-color: transparent;
top: 0; left: 50%; transform: translate(-50%, -50%);
}


.wrap {
white-space: normal;
}

video.mobile-vid-half {
width: 100%;
display: inline-block;
margin: 0;
padding: 0;

margin-bottom: -8px;
}

.inline-block {
width: 45%;
margin: 3%;
display: inline-block;
text-align: center;
}

img.acad-front-3 {
position: relative;
width: 80%;
margin: 0 auto;
padding-top: 3%;
text-align: center;
z-index: 999;
}

img.acad-front-4 {
margin-top: -34%;
width: 85%;
position: relative;
z-index: 999;
}

.desktop {
width: 100%;
margin-top: 90px;
padding-top: 10px;
text-align: center;
}

.mobile {
display: none;
}


.width-80 {
width: 83%;
margin: 30px auto;
background: white;
background: linear-gradient(to bottom left, #7b439770, transparent, transparent, rgba(0, 0, 0, 0.555));
background-size: 200% 200%;
-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
}


.width-96 {
width: 46%;
display: inline-block;
vertical-align: middle;
margin: 0 auto;
padding-left: 2%;
padding-right: 2%;
}

video.whyus-vid {
position: relative;
display: inline-block;
width: 100%;
vertical-align: middle;
}
.top-150 {
padding-top: 100px;
}

video.width-100 {
height: 80vh;
width: auto;
margin: 0 auto;
}

.sideways {
position: relative;
padding-top: 0px;
padding-left: 100px;
left: 5px;
color: black;
width: auto;
display: block;
font-family: "Sanz-Thin-Reg";
transform-origin: 0 0;
transform: rotate(-90deg);
font-size: 22px;
width: 400px;
background: linear-gradient(to left, black, #7b4397, black, #e72f60);
background-size: 300% 300%;
-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
background-clip: text;
margin-top: 350px;
-webkit-background-clip: text;
color: transparent;
font-family: "Sanz-Thin", sans-serif;
letter-spacing: 9.5px;
z-index: 999999999;
}

.moving-grad-8 {
  background: linear-gradient( transparent, transparent, transparent, transparent, transparent, rgba(255, 255, 255, 0.241), transparent, transparent, transparent);
  -webkit-animation: slide-back-forth 10s  infinite;
  -moz-animation: slide-back-forth  10s  infinite;
  animation: slide-back-forth 10s infinite;
  background: linear-gradient(10deg, transparent, rgb(160, 160, 160),  rgb(100, 100, 100), rgb(236, 236, 236),  rgb(160, 160, 160), transparent);
  width: 150%;
  left: 3%;
  height: 2px;
  margin-top: -3px;
  position: absolute;
}

.rotate-90 {
  transform: rotate(90deg);
  z-index: 999;
  width: 300px;
  position: absolute;
  left: -116px;
}




video.inline-block {
vertical-align: top;
padding-top: 0px;
width: 100%;
position: relative;
display: inline-block;
height: auto;
}

.courses-page {
padding-top: 60px;
min-width: unset;
width: 100%;
overflow: hidden;
}

/* additions */



img.full-op {
  opacity: 1;
}

.inline-left {
  width: 2%;
  height: 10em;
  display: inline-block;
  vertical-align: top;
}

.inline-right {
  display: inline-block;
  padding-top: .25em;
  padding-bottom: 3.25em;
  width: 98%;
  height: auto;
  vertical-align: top;
}



h1 {
  position: relative;
  color: white;
  font-size: 34px;
  font-weight: 200;
  text-align: left;
  padding-left: 20px;

  text-transform: uppercase;
  z-index: 99999;
  width: 100%;
}

h2 {
  position: relative;
  color: white;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  padding-left: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-transform: uppercase;
  z-index: 99999;
  white-space: normal;
  width: 90%;
  padding-top: 5px;
  padding-bottom: 2px;
  line-height: 1.5em;
}

.text-grad-purp {
  color: transparent;
  background-clip: text;
  background: linear-gradient(to  right, white, white);
  background-clip: text;
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-font-smoothing: antialiased;
}

button.intro-button {
  position: absolute;
  
  top: 91%; left: 0%; transform: (0%, -30%);
  padding-left: 14px;
  z-index: 100;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  z-index: 99999;

}

button.center {
  padding-left: unset;
  margin: 0 auto;
  text-align: center;
  bottom: 9.5%;
  top: unset;
  left: 50%; transform: translate(-50%);
  width: fit-content;
}

.inner-button {
  padding: 12px;
  border: 1px solid white;
  margin: 3px;
  margin-top: 10px;
  font-size: 3vw;
}

button {
  background: transparent;
  box-shadow: none;
  border: none;
}



.vid-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.212);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.536), rgba(0, 0, 0, 0.212));
  height: 102%;
  width: 100%;
  z-index: 1;

  top: 0;
}

.vid-overlay2 {
  position: absolute;
  background: rgba(0, 0, 0, 0.212);
  background: linear-gradient(150deg,rgba(0, 0, 0, 0.452), transparent, rgba(128, 0, 128, 0.315) );
  background: none;
  height: 102%;
  width: 100%;
  z-index: 0;

  top: 0;
}
.top-150 {
  padding-top: 135px;
}

video.width-100 {
  width: 100%;
  height: auto;
  background: -moz-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%);
}

.sideways {


}

.sxn {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 40vh;
  padding-bottom: 10px;
  background: linear-gradient(transparent, rgba(255, 255, 255, 0), white, white);
  }

  .sxn-backed {
    background: white;
  }

.inline-paragraph {
  display: inline-block;
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4em;
  opacity: 1;
  letter-spacing: .18px;
}

.inline-para {
  display: inline-block;
  position: relative;
  width: 80%;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4em;
  opacity: 1;
  letter-spacing: .18px;
}

.paragraph-title {
  font-size: 22.5pt;
  padding-top: 18px;
  padding-bottom: 10px;

  color: #6c2391b6;
  background: linear-gradient(to left, black, #7b4397, black, #e72f60);
  background: linear-gradient(to left, black, #772dac, black, #f43ea8);
  background-size: 300% 300%;
  -webkit-animation: WelcomePageGradient 4s ease infinite;
  -moz-animation: WelcomePageGradient 4s ease infinite;
  animation: WelcomePageGradient 4s ease infinite;
  background-clip: text;

  -webkit-background-clip: text;
  -webkit-font-smoothing: antialiased;
  color: transparent;
  z-index: 9999;
  

}

a.walkthru-link, i {
  color: #772dac;
}

.click-thru {
  font-weight: 600;
  padding-top: 20px;
  font-size: 8.5pt;
  color: #434343;
  font-family: "Sanz-Reg", sans-serif;
  padding-bottom: 10px;
}

.space-title {
  font-size: 24px;
  padding-top: 30px;
}



video.inline-block {


  display: block;
  width: auto;
  margin-left: -1px;
  overflow: hidden;
  width: 100%;
  margin-bottom: -10px;
}
.class-sample-row {
  width: 100%;
  white-space: nowrap;
  overflow-y: scroll;
}



.class-sample {
  width: 60%;
  margin: 2%;
  overflow: hidden;
  vertical-align: top;
  display: inline-block;
  transition: .5s;
  padding: 2px;
  white-space: normal;
  border: .5px solid rgba(0, 0, 0, 0.534);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 13px;
}


.class-sample img {
  position: relative;
  width: 96%;
  padding: 2%;
  margin: 0 auto;
  top: 0%; transform: translate(0, 0%);
  vertical-align: top;
  height: auto;
  margin: 0px;
  margin-bottom: 0;
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
}

.course-program-title {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 200;
  padding-left: 10px;
  color: rgb(0, 0, 0);
  background: linear-gradient(to left, rgba(3, 3, 3, 0.26), #7b4397, #7b4397, rgb(0, 0, 0), #e72f60, #e72f609c);
  background: linear-gradient(to left, black, #7b4397, black, #e72f60);
  background-size: 100% 100%;
  background: transparent;

  -webkit-animation: WelcomePageGradient 10s ease infinite;
  -moz-animation: WelcomePageGradient 10s ease infinite;
  animation: WelcomePageGradient 10s ease infinite;

  padding-top: -2px;
  -webkit-background-clip: text;
  -webkit-font-smoothing: antialiased;
  color: rgba(71, 71, 71, 0.384);
  color: rgb(47, 5, 53);
  text-align: left;
}

.course-program-title.b {
position: absolute;
top: 10%;
font-size: 24px;
font-weight: 300;
transform: translate(0, -50%);
border-top: 2px solid white;
border-top: none;
width: 52%;


}


.course-program-subtitle {
  text-transform: uppercase;
  padding-left: 10px;
  padding-top: 10px;
  font-weight: 500;
  font-size: 9.5pt;
  text-align: left;
  color: rgba(18, 18, 18, 0.795);
}

.course-program-subtitle.b {
  position: absolute;
  bottom: unset;
  top: 50%;
  font-size: 9.5pt;
}

.course-program-subtitle.e {
  position: absolute;
  bottom: unset;
  top: 27%;
  font-size: 9.5pt;
}

.course-program-subtitle.f {
  position: absolute;
  bottom: unset;
  top: 37%;
  font-size: 9.5pt;
}

.course-program-subtitle.c {
  position: absolute;
  bottom: 20%;
  transform: translate(0, -50%);
}


.class-sample-row-2 {
  width: 100%;

}


.class-sample-2 {
  width: 100%;
  height: auto;
  display: block;
}

.class-sample-2 img {
  position: relative;
  width: 96%;
  margin: 0 auto;
  height: auto;
  margin: -2px;
  margin-bottom: -10px;
}

.pad-top-90 {
  padding-top: 10px;
}

.inline-block {
  width: 100%;
  display: block;
  text-align: center;
  padding-bottom: 20px;
}
.mobile {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
}

.enroll-now {
  position: absolute;
  top: 60%; right: 20px;
  transform: translate(0, -50%);
  color: white;
  font-weight: 400;
  padding-top: 9.5px; padding-bottom: 9.5px;
  padding-left: 13px; padding-right: 13px;

  background: linear-gradient(to left, black, #7b4397, black, #e72f60);

  background-size: 300% 300%;

  -webkit-animation: WelcomePageGradient 10s ease infinite;
  -moz-animation: WelcomePageGradient 10s ease infinite;
  animation: WelcomePageGradient 10s ease infinite;
  z-index: 1;
  border-radius: 20px;
}

.enroll-now-sold {
  position: absolute;
  top: 0px; left: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: 300;

  background: linear-gradient(to right, rgba(110, 110, 110, 0.863), rgba(219, 219, 219, 0.63));
  z-index: 1;

}

.enroll-text {
  position: absolute;
  top: 50%; right: 20px;
  transform: translate(0, -50%);
  right: 20px;
  color: black;
  font-weight: 300;
  padding-top: 8.5px; padding-bottom: 8.5px;
  padding-left: 12px; padding-right: 12px;
}
.closed-container {
  padding: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(214, 214, 214, 0.418);
  background: linear-gradient(to left, black, #7b4397, black, #e72f60);
  background-size: 300% 300%;

  -webkit-animation: WelcomePageGradient 10s ease infinite;
  -moz-animation: WelcomePageGradient 10s ease infinite;
  animation: WelcomePageGradient 10s ease infinite;
  color: transparent;
  background-clip: text;
  text-transform: uppercase;
  font-size: 8pt;
  font-weight: 400;
}

.desktop {
  display: none;
}

.acad-top-section {
  display: block;
  position: unset;
  width: unset;
 
}

.intro-btns {
  display: block;
  position: relative;
  margin-top: 5%;
  width: 100%;
}

.intro-btn {
  display: inline-block;
  width: 35%;
  margin: 2.5%;
  padding: 2.5%;
  padding-top: 3%;
  padding-bottom: 3%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 9.5pt;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #353535ee;
}

video.mobile-vid.a {
  position: absolute;
  top: 135px;
  opacity: .45;
  width: 100%;
}

.width-96 {
  width: 96%;
  margin: 0 auto;
  display: block;
  margin-top: -70px;
  padding-left: unset; padding-right: unset;
}

video.whyus-vid {
  position: relative;
  display: block;
  width: 100%;
}

.apprentice-page {
  padding-top: 140px;
}

.smaller-bf {
font-size: 28pt;
margin-bottom: 8px;
}

.main-title {
font-size: 22pt;
text-transform: uppercase;
font-family: "Sanz-Thin", sans-serif;
position: absolute;
width: 100%;
top: -150px;
left: 50%; transform: translate(-50%);
color: white;
}


.trans-back {

    font-size: 14px;
    background: white;
    color: rgb(51, 51, 51);
}

img.acad-front-3 {
  display: none;
}

img.acad-front-4 {
  display: none;
}

img.acad-front {
  position: relative;
  width: 94%;
  left: 50%;
  margin-top: -37%;
  padding-bottom: 20px;

  transform: translate(-50%);
  background-color: transparent;
  display: block;
}
img.acad-front-2 {
  position: relative;
  width: 110%;
  background: transparent;
  height: auto;
  left: 50%; transform: translate(-50%);
  margin: 0 auto;
  margin-top: 4%;
  text-align: center;
display: block;
  z-index: 2;
}

.course-wrap {
width: 100%;
vertical-align: top;

padding: 0;
margin: 3px auto;
margin-bottom: 0;
background: white;
position: relative;
display: block;
font-weight: 200;
font-size: 9pt;
text-align: center;
  
}

.review-container {
  width: 86%;
  background: white;
  display: inline-block;
  height: fit-content;
  white-space: normal;
  vertical-align: top;
  text-align: center;
  margin: 5px;
  border-radius: 12px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.page {

  display: block;
  margin-top: 110px;
  position: relative;
}


.purp-back {

}

.entry-text-low {
  color: rgba(51, 48, 51, 0.836);
  font-weight: 200;
  font-size: 11pt;
  text-align: center;
  letter-spacing: 0.5px;
  
  
  }

.page {
  text-align: center;
}

.desktop-pad {
  padding: 0; 
}

.full-btn {
  position: fixed;
  z-index: 9999999;
  top: 90%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -95%);
}

.body-page {
  width: 100%;
  margin-top: 20px;
}

.half {
  width: 100%;
  position: relative;
  display: block;
}

.third {
  display: block;
  width: 100%;

  padding-bottom: 30px;
  position: relative;

}


.video-name {
  position: absolute;
  z-index: 1000;
}


img.video-name-image {
  width: 65%;
  height: auto;
  position: absolute;
  text-align: center;
  bottom: 20px;
  right: 0px;
  z-index: 300;
  display: block;
}

.remaining-title {
  color: white;
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 300;
  font-size: 10.5pt;
  font-weight: 300;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 300;
  text-align: left;
}




.mobile-banner-overlay {

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.753), transparent, rgba(0, 0, 0, 0.753));
  position: absolute;
  top: 0;

  z-index: 0;
}


.banner-overlay {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
  top: 0;left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.103), rgba(0, 0, 0, 0.418));

}

video.main-vid {
  display: block;
  width: 100%;
  position: relative;
  top: 0px;
  left: 0;
  height: 100%;
  opacity: 1;
  transform: rotate(90deg);
}




  





img {
height: 100%;
}


.half {
position: relative;
display: block;
width: 100%;
overflow: hidden;
vertical-align: middle;
height: 65vh;
}

.padding-20 {
padding-top: 80px;
padding-bottom: 80px;
}

.right-navbar {
display: none;
}

.mobile-no {
display: none;
}

.mobile-yes {
display: block;
}

.section {
white-space: nowrap;
width: 100%;
overflow: hidden;
margin-top: -5px;
}

.mobile-third {
position: relative;
display: inline-block;
width: 50%;
white-space: nowrap;
vertical-align: middle;
overflow: hidden;
margin-bottom: -5px;
}

.mobile-vid {

}

video.mobile-vid {
position: relative;
top: 10px;
width: 100%;
height: 100%;
min-height: 360px;
margin: 0 auto;
text-align: center;
left: 0;
transition: .4s ease-in-out;



}

video.mobile-vid-half {
width: 50%;
display: inline-block;
margin: 0;
padding: 0;
margin-bottom: -8px;
}

.block {
width: 100%;
margin: 0 auto;
min-height: 420px;
margin-bottom: -70px;
height: fit-content;
position: relative;
background: transparent;
overflow: hidden;
vertical-align: middle;
text-align: left;
display: block;
}

.block-full {
width: 100%;
margin: 0 auto;
min-height: 60vh;
height: fit-content;
padding-top: 80px;
padding-bottom: 20px;
position: relative;
background: linear-gradient(to bottom, transparent, white, white, white, white, white);
vertical-align: middle;
text-align: left;
display: block;
}

.block-inside {
width: 100%;
margin-top: 0%;
padding-top: 0;
height: 100%;
}

.moving-gradient-2 {
background: linear-gradient(to bottom right, #e72f60, #7b4397);
background-size: 300% 300%;

-webkit-animation: WelcomePageGradient 4s ease infinite;
-moz-animation: WelcomePageGradient 4s ease infinite;
animation: WelcomePageGradient 4s ease infinite;
}

.action-button {
display: block;
position: relative;
z-index: 900000;
background-color: transparent;
width: 130px;
height: 42px;
margin: 0px auto;
margin-top: -10px;
vertical-align: middle;
font-size: 40px;
color: white;
padding: 0px;
text-align: center;
text-transform: uppercase;
font-size: 13px;
-webkit-appearance: none;
transition: .4s;
  border-image: linear-gradient(to right, #005e7f, #61b6cd);
  border-radius: 50px;
  background-size: 100%;
  background: rgb(0, 0, 0);
  border: 2px transparent;
  border-radius: 50px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  vertical-align: middle;
}

.action-button-inside {
display: block;
position: absolute;
z-index: 1000000;
background-color: #ffffff;
width: 120px;
height: 42px;
border-radius: 50px;
padding: 20px;

}
.action-button-text {
display: block;
position: absolute;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 9pt;
background-color:transparent;

padding-top: 11px;
padding-bottom: 11px;
vertical-align: middle;
top: 49%;
transform: translate(0, -50%);
text-align: center;
width: 99%;
color: rgb(255, 255, 255);
border-radius: 50px;
}

.acad-front-container {
position: absolute;
overflow: hidden;
width: 100%;
background: transparent;
background-color: transparent;
top: 0; left: 50%; transform: translate(-50%, -50%);
}



img.acad-front-desktop {
position: relative;
width: 120%;
left: 50%;
padding-top: 50px;
padding-bottom: 100px;

transform: translate(-50%);
background-color: transparent;
display: none;
}

.wrap {
white-space: normal;
}

.switch-container {
display: block;
width: 97.5%;
margin: 0 auto;
margin-bottom: 5px;
font-size: 10pt;
}

span.switch-left {
display: inline-block;
width: 45%;
padding: 2.5%;
text-align: center;
box-shadow: inset 0px 0px 4px rgba(0,0,0,0.5);
}

span.switch-left.selected {
display: inline-block; 
width: 45%;
padding: 2.5%;
text-align: center;
background: black;
color: white;
}

span.switch-right {
display: inline-block;
width: 45%;
padding: 2.5%;
text-align: center;
box-shadow: inset 0px 0px 4px rgba(0,0,0,0.5);
}

span.switch-right.selected {
display: inline-block;
width: 45%;
padding: 2.5%;
text-align: center;
background: black;
color: white;
}

.slide-right {
transition: .5s;
}

.slide-left {
transition: .5s;
}



.width-100 {
position: relative;
display: block;
width: 100%;
overflow: hidden;
}

.width-120 {
width: 100%;

}


.white {
color: white;
}

a.bnb {
color: black;
}

.bnbc {
  color: #4d4d4d;
  font-size: 12px;
  padding-left: 10px;
  font-family: "Sanz-Reg", sans-serif;
}

.bnb {
  color: #4d4d4d;
  font-family: "Sanz-Bold", sans-serif;
}

.intro-headers {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 78vh;
  z-index: 100;
}
}

a.regular {
  position: relative;
  top: 2px;
}

.width-90 {
  width: 98%;
  margin: 0 auto;
  text-align: left;
  white-space: normal;
}

.s {
  text-transform: none;
}

.c {text-align: center;
margin: 0 auto;}

a.c {
  padding: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.desktop-navbar-link.blk {
  color: black;
  border-right: .5px solid rgba(0, 0, 0, 0.45);
}

.wht {
  color: white;
}



@media (max-width: 380px) {
  .sanz-bold {
    font-size: 32px;
  }
}
span.italic.all-courses-spacing {
  margin-top: 10px;
  font-size: 8pt;
  line-height: 1.4em;
  position: absolute;
  top: 170px;
  left: 10px;
  white-space: normal;
}
.nomargtop {
  margin-top: 0;
}

img.upside-down {

width: 32%;
}

.yellow-stars {
  color: #f8b80d;
}

.grad-blk-trans {
  margin-top: -20px;
  padding-top: 0px;
  color: white;
  background: linear-gradient(to bottom, black, transparent);
}



.menu-btn {
  background: linear-gradient(to left, black, #7b4397, black, #e72f60);

  background-size: 300% 300%;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0.1);
  font-weight: 600;

  border: 1px solid #7b4397;
  -webkit-animation: WelcomePageGradient 10s ease infinite;
  -moz-animation: WelcomePageGradient 10s ease infinite;
  animation: WelcomePageGradient 10s ease infinite;
  z-index: 99;

}

.menu-btn:hover {
  cursor: pointer;
}


:root {
  --gold: #ffb338;
  --silver: #E7EBEC;

  --light-shadow: #9FA0A0;
  --dark-shadow: #454747;
}
body {
  margin: 0;
}
.wrapper {
  background: radial-gradient(#272727, #1b1b1b);
  background: transparent;
  display: grid;
  grid-template-areas: 'overlap';
  place-content: center;
  text-transform: uppercase;
  height: auto;
}
.wrapper > div {
  background-clip: text;  
  -webkit-background-clip: text;
  color: #363833;
  font-family: 'Sanz-Bold', sans-serif;
  font-weight: 600;
  font-size: clamp(1.5em, 7vw, 7rem);
  grid-area: overlap;
  letter-spacing: 1.4px;
  -webkit-text-stroke: 4px transparent;
}
div.bg {
  background-image: repeating-linear-gradient( 105deg, 
    var(--silver) 0% , 
    var(--dark-shadow) 5%,
    var(--silver) 12%);
  color: transparent;
  filter: drop-shadow(5px 15px 15px black);
  transform: scaleY(1.05);
  transform-origin: top;
}
div.fg{
  background-image: repeating-linear-gradient( 5deg,  
    var(--silver) 0% , 
    var(--light-shadow) 23%, 
    var(--silver) 31%);
  color: #1e2127;
  transform: scale(1);
}

.inkbrush-details {
  position: absolute;
  text-align: center;
  font-size: 14px;
  left: 50%; transform: translate(-50%);
  margin-top: 250px;
  z-index: 999999999;
  color: #cecece;
}

.inkbrush-details a{
  color: #7b4397;
}

.left-box {
  position: relative;
  width: 95.5%;
  overflow: hidden;
  border: 1px solid black;
  border-radius: 25px;
  padding-bottom: 10px;
  margin: 7px auto;
  background: black;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.06) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.05) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.02) 0px 2px 1px, rgba(0, 0, 0, 0.04) 0px 4px 2px, rgba(0, 0, 0, 0.03) 0px 8px 4px, rgba(0, 0, 0, 0.04) 0px 16px 8px, rgba(0, 0, 0, 0.04) 0px 32px 16px;
}

.right-box {
  position: relative;
  display: inline-block;
  width: 45%;
  overflow: hidden;
  margin: 1.5%;
  padding: .5%;
  background: white;
  border-radius: 25px;
  padding-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.06) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.05) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.02) 0px 2px 1px, rgba(0, 0, 0, 0.04) 0px 4px 2px, rgba(0, 0, 0, 0.03) 0px 8px 4px, rgba(0, 0, 0, 0.04) 0px 16px 8px, rgba(0, 0, 0, 0.04) 0px 32px 16px;
}

.individual-course-page {
  width: 100%;
  overflow: hidden;
}

.curriculum-area {
  width: 90%;
  margin: 0 auto;
}

.width-90c {
  width: 90%;
  margin: 0 auto;
  padding: 5%;
  background: #f4f4f4;
}

.pad-no-top {
  padding-top: 10px;
  margin-bottom: 0;
  font-size: 11px;
}

.pad-less-top {
  padding-top: 2px;
  margin-bottom: 0;
  font-size: 11px;
}

.border-bottom {

  margin-bottom: 10px;
  margin-top: 10px;
}

.inline-nav-btn {
  display: inline-block;
  margin-bottom: 20px;
}

*a:hover {
  cursor: pointer;
}

* {
  -webkit-font-smoothing: antialiased;
}


.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}